import { Injectable } from '@angular/core';
import { CustomerService } from '@otrack-lib/core/services/customer.service';
import { InvoiceService } from '@otrack-lib/core/services/invoice.service';
import { PaymentService } from '@otrack-lib/core/services/payment.service';
import { ProductService } from '@otrack-lib/core/services/product.service';
import { SalesOrderService } from '@otrack-lib/core/services/sales-order.service';
import { OrderTypes } from '@otrack-lib/enums';
import { ICustomerDetail } from '@otrack-lib/models/customer/customer.model';
import { IExpense } from '@otrack-lib/models/expense/expense.model';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';
import { IOrder } from '@otrack-lib/models/order/order.model';
import { ITransaction } from '@otrack-lib/models/order/transaction.model';
import { IPayment } from '@otrack-lib/models/payment/payment.model';
import { IProductCategory } from '@otrack-lib/models/product/product.category';
import { ICategoryProduct, IQuickProductDetail } from '@otrack-lib/models/product/quick-order.models';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InvoiceState } from '../common/models';
import { OperationType } from '../common/models/operationtype.enum';


@Injectable({
  providedIn: 'root'
})
export class InMemoryService {
  public newOrderAllow = false;
  public productFlowList: IProductCategory[] = [];
  private productBreadcrumbs: string[] = [];
  public currentOrder: IOrder[] = [];
  public memo = '';
  public isTaxApply: boolean;
  public taxId: number | null;
  public currentCustomerId: number;
  public customerDetail: ICustomerDetail;
  public lastInvoice: IInvoice;
  public lastPayment: IPayment;
  public saleOrderId: number;
  public editInvoiceOrderId: number;
  public editInvoiceInfo: {
    invoiceNumber?: string;
    invoiceBy?: string;
    isTaxableInvoice?: boolean;
  } = {};
  public currentOrderChange: BehaviorSubject<IOrder[]> = new BehaviorSubject<
    IOrder[]
  >([]);
  public quickOrderList: BehaviorSubject<
    ICategoryProduct[]
  > = new BehaviorSubject<ICategoryProduct[]>([]);
  public currentOperation: OperationType;
  public currentOrderSelected: OrderTypes;
  public currentExpense: IExpense;

  constructor(
    //   private stateManagerService: StateManagerService,
    private invoiceService: InvoiceService,
    private customerService: CustomerService,
    private saleOrderService: SalesOrderService,
    private paymentService: PaymentService,
    private productService: ProductService
  ) { }

  resetForNewCustomer() {
    this.quickOrderList.next([]);
    this.currentOrder = [];
    this.currentOrderChange.next([]);
    this.customerDetail = null;
    this.currentCustomerId = null;
    this.memo = '';
    this.lastInvoice = null;
    this.productFlowList = [];
    this.newOrderAllow = false;
    this.editInvoiceOrderId = 0;
    this.saleOrderId = 0;
    this.editInvoiceInfo = null;
    this.taxId = null;
  }

  resetOrder() {
    this.currentOrder = [];
    this.quickOrderList.next([]);
    this.currentOrderChange.next([]);
    this.memo = '';
    this.isTaxApply = this.customerDetail.isTaxable;
    this.lastInvoice = null;
    this.productFlowList = [];
    this.newOrderAllow = true;
    this.editInvoiceOrderId = 0;
    this.saleOrderId = 0;
  }

  resetOrderAfterSubmit() {
    this.currentOrder = [];
    this.quickOrderList.next([]);
    this.currentOrderChange.next([]);
    this.memo = '';
    this.isTaxApply = this.customerDetail.isTaxable;
    this.productFlowList = [];
    this.newOrderAllow = true;
    this.editInvoiceOrderId = 0;
    this.saleOrderId = 0;
    this.editInvoiceInfo = null;
  }

  resetExpense() {
    this.currentExpense = null;
  }

  isCurrentOrderContainTempId() {
    return (
      this.currentOrder.findIndex(c => (c.tempId ? c.tempId > 0 : null)) > -1
    );
  }

  mapSalesOrderToInvoiecObject(salesOrder: any): IInvoice {
    const invoiceObj: IInvoice = {
      orderType: OrderTypes.SalesOrder,
      orderId: salesOrder.salesOrderId,
      invoiceNumber: salesOrder.salesOrderId.toString(),
      invoiceDate: salesOrder.orderDate,
      invoiceBy: salesOrder.orderBy,
      totalAmount: salesOrder.totalAmount,
      taxPercent: salesOrder.taxPercent,
      totalTax: salesOrder.totalTax,
      isSync: true,
      customerName: salesOrder.customerName,
      companyName: salesOrder.customerName,
      customerId: salesOrder.customerId,
      IsTaxable: salesOrder.isTaxable,
      //  IsTaxable: salesOrder.isTaxable,
      items: salesOrder.items,
      status: salesOrder.status,
      deliveryType: salesOrder.deliveryType,
      shippingAddress: salesOrder.shippingAddress,
      pickupDate: salesOrder.pickupDate
    };

    return invoiceObj;
  }

  restoreSession(invoiceState: InvoiceState) {
    this.currentCustomerId = invoiceState.customer.customerId;
    this.currentOrder = invoiceState.invoiceOrders;
    this.currentOrderChange.next(this.currentOrder);
    this.memo = invoiceState.memo;
    this.isTaxApply = invoiceState.isTaxApply;
    this.taxId = invoiceState.taxId;
    this.newOrderAllow = true;
    this.customerDetail = invoiceState.customer;
  }

  getCustomerPreferredOrders(): Observable<ICategoryProduct[]> {
    return this.invoiceService.getCustomerPreferedList(this.currentCustomerId);
  }

  getTransactionModel(res: any): ITransaction[] {
    if (!res) {
      return null;
    }

    const output: ITransaction[] = [];
    res.forEach((element: {
      amount: any; date: any; id: string | number;
      invoiceBalance: any; transaction: any; transactionNumber: any; customerId: any; transactionType: any;
    }) => {
      output.push({
        amount: element.amount,
        date: element.date,
        id: +element.id,
        amountDue: element.invoiceBalance,
        transaction: element.transaction,
        transactionNumber: element.transactionNumber,
        customerId: element.customerId,
        transactionType: element.transactionType,
      });
    });
    return output;
  }


  // getproduct(parentProductId: number): Observable<IProductCategoryView> {
  //   return ;
  // }

  getProductListBySearch(term: string): Observable<string[]> {
    if (term && term.length >= 2) {
      return this.productService.getproductListBySearch(term);
    } else {
      return of([]);
    }
  }

  getproductByName(
    customerId: number,
    productName: string
  ): Observable<IQuickProductDetail> {
    productName = productName.replace('\'', '\'\'');
    return this.productService.getproductByName(customerId, productName);
  }

  // getproductDetail(
  //   customerId: number,
  //   productId: number
  // ): Observable<IQuickProductDetail> {
  //   return this.productService.getproductDetailByCustomer(
  //     customerId,
  //     productId
  //   );
  // }

  getProductDetailByBarcode(
    customerId: number,
    barcode: string
  ): Observable<IQuickProductDetail[]> {
    return this.productService.getProductDetailByBarcode(customerId, barcode);
  }

  resetProductFlow() {
    this.productFlowList = [];
    this.productBreadcrumbs = [];
  }

  getProductBreadCrumb(): string {
    let breakcrumbstring = '';
    this.productBreadcrumbs.forEach(element => {
      breakcrumbstring += element !== '' ? element + ' > ' : '';
    });
    return breakcrumbstring;
  }

  productForward(category: IProductCategory): void {
    this.productFlowList.push(category);
    this.productBreadcrumbs.push(category.description);
  }

  productBackward(): IProductCategory {
    this.productBreadcrumbs.pop();
    return this.productFlowList.pop();
  }

  productBackwardByCategoryId(categoryId: number): IProductCategory {
    let category: any;
    for (let index = this.productFlowList.length - 1; index >= 0; index--) {
      category = this.productFlowList.pop();
      if (category.categoryId === categoryId) {
        this.productFlowList.push(category);
        return category;
      }
    }
    return null;
  }

  getProductFromCurrentOrder(inProductId: number, tempId?: number): IOrder {
    if (tempId && tempId >= 0) {
      return this.currentOrder.filter(item => item.tempId === tempId && item.productId === inProductId)[0];
    } else {
      return this.currentOrder.filter(
        item => item.productId === inProductId
      )[0];
    }
  }

  addLineItemInCurrentOrderList(order: IOrder): Boolean {
    if (order.quantity !== 0) {
      this.addOrder(order);
    }
    return true;
  }

  // TODO : Rmeoved - added replica in
  updateCurrentOrderList(order: IOrder): Boolean {
    const index = this.currentOrder.findIndex(c => c.tempId === order.tempId && order.productId === c.productId);

    if (index > -1) {
      if (order.quantity === 0) { // Fixed: wired bug related to order item replacemenet
        this.currentOrder.splice(index, 1);
      } else {
        this.currentOrder[index] = order;
        this.currentOrderChange.value[index] = order;
      }
      this.currentOrderChange.next(this.currentOrder);
      // this.stateManagerService.updateOrder(this.currentOrder);
      return true;
    } else {
      if (order.quantity !== 0) {
        this.addOrder(order);
        this.currentOrderChange.next(this.currentOrder);
      }
      return true;
    }
  }

  updateCurrentCategoryOrderList(orderList: IOrder[]) {
    orderList
      ? orderList.forEach(order => {

        this.quickOrderList.value.forEach(categoryProduct => {
          const index = categoryProduct.products.findIndex(c => c.tempId === order.tempId && c.productId === order.productId);
          if (index > -1) {
            categoryProduct.products[index].quantity = order.quantity;
            categoryProduct.products[index].basePrice = order.price;
          } else {
            const indexByProductId = categoryProduct.products.findIndex(c => c.tempId === undefined
              && c.productId === order.productId);

            if (indexByProductId > -1) {
              categoryProduct.products[indexByProductId].quantity = order.quantity;
              categoryProduct.products[indexByProductId].basePrice = order.price;
              categoryProduct.products[indexByProductId].tempId = order.tempId;
            }
          }
        });
      })
      : EMPTY;
  }

  // TODO : Rmeoved - added replica in
  deleteCurrentOrderList(order: IOrder): Boolean {
    if (this.quickOrderList && this.quickOrderList.value) {
      this.quickOrderList.value.forEach(categoryProduct => {
        const index1 = categoryProduct.products.findIndex(c => c.tempId === order.tempId && c.productId === order.productId);
        if (index1 > -1) {
          categoryProduct.products[index1].tempId = undefined;
          categoryProduct.products[index1].quantity = 0;
        }
      });
    }

    const index = this.currentOrder.findIndex(c => c.tempId === order.tempId && c.productId === order.productId);
    if (index > -1) {
      this.currentOrder.splice(index, 1);
      this.currentOrderChange.next(this.currentOrder);
      //  this.stateManagerService.updateOrder(this.currentOrder);
      return true;
    } else {
      return false;
    }
  }

  updateCurrentOrderUsingCategoryPrdList(
    categoryProduct: ICategoryProduct[]
  ): void {
    categoryProduct.forEach(c => {
      c.products.forEach(prod => {
        // if ( prod.quantity !== 0 ) {
        this.updateCurrentOrderList({
          productId: prod.productId,
          actualPrice: prod.actualPrice,
          quantity: prod.quantity,
          price: prod.basePrice,
          isTaxable: prod.isTaxable,
          categoryName: prod.categoryName,
          rootCategoryName: c.categoryName,
          productName: prod.name
        });
        // }
      });
    });
  }

  resetQuantityCategoryProductList(categoryProduct: ICategoryProduct[]) {
    categoryProduct.forEach(c => {
      c.products.forEach(p => {
        p.quantity = 0;
      });
    });
  }

  updateCategoryProductFromCurrentOrder(
    categoryProduct: ICategoryProduct[]
  ): void {
    this.resetQuantityCategoryProductList(categoryProduct);
    let found = false;
    this.currentOrder.forEach(o => {
      found = false;
      categoryProduct.forEach(element => {
        element.products.forEach(product => {
          if (product.tempId === o.tempId && product.productId === o.productId) {
            product.quantity = o.quantity;
            product.basePrice = o.price;
            found = true;
          } else if (
            product.productId === o.productId &&
            product.quantity !== 0
          ) {
            o.categoryName = product.categoryName;
            o.rootCategoryName = product.rootCategoryName;
            o.productName = product.name;
          }
        });
      });

      if (!found) {
        categoryProduct.forEach(element => {
          element.products.forEach(product => {
            if (product.productId === o.productId && product.quantity === 0) {
              product.quantity = o.quantity;
              product.basePrice = o.price;
              found = true;
            } else if (product.productId === o.productId && product.quantity !== 0) {
              o.categoryName = product.categoryName;
              o.rootCategoryName = product.rootCategoryName;
              o.productName = product.name;
            }
          });
        });
      }

      if (!found) {
        const productDetail: IQuickProductDetail = {
          categoryName: o.categoryName,
          productId: o.productId,
          name: o.productName,
          description: o.description,
          quantity: o.quantity,
          basePrice: o.price,
          actualPrice: o.actualPrice,
          isTaxable: o.isTaxable,
          tempId: o.tempId,
          rootCategoryName: o.rootCategoryName
        };
        let catFound = false;
        categoryProduct.forEach(cat => {
          if (cat.categoryName === o.rootCategoryName) {
            cat.products.push(productDetail);
            catFound = true;
            return;
          }
        });
        if (!catFound) {
          const productList: IQuickProductDetail[] = [];
          productList.push(productDetail);
          categoryProduct.push({
            categoryName: o.rootCategoryName,
            products: productList
          });
        }
      }
    });
  }

  updateCategoryPrdFrmCurrOrderWithoutTempId(
    categoryProduct: ICategoryProduct[]
  ): void {
    this.resetQuantityCategoryProductList(categoryProduct);
    let found = false;
    this.currentOrder.forEach(o => {
      found = false;
      categoryProduct.forEach(element => {
        element.products.forEach(product => {
          if (product.productId === o.productId && product.quantity === 0) {
            // for 1st time
            product.quantity = o.quantity;
            product.basePrice = o.price;
            found = true;
          } else if (product.productId === o.productId) {
            o.categoryName = product.categoryName;
            o.rootCategoryName = product.rootCategoryName;
            o.productName = product.name;
          }
        });
      });

      if (!found) {
        const productDetail: IQuickProductDetail = {
          categoryName: o.categoryName,
          productId: o.productId,
          name: o.productName,
          description: o.description,
          quantity: o.quantity,
          basePrice: o.price,
          actualPrice: o.actualPrice,
          isTaxable: o.isTaxable,
          tempId: o.tempId,
          rootCategoryName: o.rootCategoryName
        };
        let catFound = false;
        categoryProduct.forEach(cat => {
          if (cat.categoryName === o.rootCategoryName) {
            cat.products.push(productDetail);
            catFound = true;
            return;
          }
        });
        if (!catFound) {
          const productList: IQuickProductDetail[] = [];
          productList.push(productDetail);
          categoryProduct.push({
            categoryName: o.rootCategoryName,
            products: productList
          });
        }
      }
    });
  }

  // TOOD: Removed Ngrx done
  addOrder(order: IOrder) {
    order.tempId = this.fixMaxTempId(this.currentOrder) + 1;
    this.currentOrder.push(order);
    this.currentOrderChange.next(this.currentOrder);
    // this.stateManagerService.updateOrder(this.currentOrder);
  }

  fixMaxTempId(orders: IOrder[]) {
    return orders.map(o => o.tempId).reduce((a, b) => Math.max(a, b), 0);
  }

  // TOOD: Removed Ngrx done SetCurrrentOrder
  loadOrder(orders: IOrder[]) {
    let count = 0;
    if (orders) {
      orders.forEach(o => {
        o.tempId = ++count;
        this.currentOrder.push(o);
      });
    }
    this.currentOrderChange.next(this.currentOrder);
  }

  // TOOD: Removed Ngrx done
  clearZeroQtyOrders() {
    this.currentOrder.forEach(c => {
      if (c.quantity === 0) {
        this.deleteCurrentOrderList(c);
      }
    });
  }

  validateCurrentOrder(): boolean {
    if (this.currentOrder.length <= 0) {
      return false;
    }

    let zeroQuantiy = true;

    this.currentOrder.forEach(c => {
      if (c.quantity !== 0) {
        zeroQuantiy = false;
        return;
      }
    });
    let total = 0;
    const taxpercent = this.customerDetail.taxPercent;

    // Total without Tax
    this.currentOrder.forEach(c => {
      total += c.quantity * c.price;
    });
    if (total < 0) {
      return false;
    }

    let taxtotal = 0;
    this.currentOrder.forEach(c => {
      // if (c.quantity !== 0) {
      if (c.isTaxable) {
        taxtotal += c.quantity * c.price * (taxpercent / 100);
      }
    });
    if (taxtotal + total < 0) {
      return false;
    }

    return !zeroQuantiy;
  }

  postOrderOnServer(
    taxApply: boolean,
    isInvoiceEdit?: boolean
  ): Observable<IInvoice> {
    // tslint:disable-next-line:prefer-const
    let invoiceRequest: IInvoice = {
      customerId: this.customerDetail.customerId,
      taxId: this.taxId ? this.taxId : this.customerDetail.taxId,
      memo: this.memo,
      orderId:
        this.editInvoiceOrderId > 0 ? this.editInvoiceOrderId : undefined, // in case of edit
      IsTaxable: this.isTaxApply ? this.isTaxApply : taxApply,
      items: this.currentOrder
    };
    if (
      this.currentOperation === OperationType.CreditMemo &&
      this.editInvoiceOrderId <= 0 &&
      this.saleOrderId <= 0
    ) {
      return this.invoiceService.postCreditMemoOnServer(invoiceRequest);
    } else {
      return isInvoiceEdit ? this.invoiceService.updateInvoiceOnServer(invoiceRequest)
        : this.invoiceService.postInvoiceOnServer(invoiceRequest);
    }
  }

  postSaleOrderOnServer(taxApply: boolean): Observable<IInvoice> {
    // tslint:disable-next-line:prefer-const
    let invoiceRequest: IInvoice = {
      customerId: this.customerDetail.customerId,
      taxId: this.taxId ? this.taxId : this.customerDetail.taxId,
      memo: this.memo,
      IsTaxable: this.isTaxApply ? this.isTaxApply : taxApply,
      items: this.currentOrder
    };
    return this.invoiceService.postSaleOrderOnServer(invoiceRequest);
  }

  deleteSaleOrder(saleOrderId: number): Observable<any> {
    return this.saleOrderService.deleteSaleOrder(saleOrderId);
  }

  convertToInvoice(saleOrderId: number): Observable<any> {
    return this.saleOrderService.convertToInvoice(saleOrderId);
  }

  updateSaleOrderOnServer(
    taxApply: boolean,
    saleOrderId: number
  ): Observable<IInvoice> {
    // tslint:disable-next-line:prefer-const
    let saleOrder: any = {
      customerId: +this.customerDetail.customerId,
      taxId: this.customerDetail.taxId,
      memo: this.memo,
      isTaxable: taxApply,
      salesOrderId: saleOrderId,
      items: this.currentOrder
    };
    return this.invoiceService.updateSaleOrderOnServer(saleOrder);
  }

  getInvoice(orderNo: number): Observable<IInvoice> {
    return this.invoiceService.getOrder(orderNo).pipe(
      tap(invoice => {
        if (invoice) {
          this.lastInvoice = invoice;
          // load edit info incase require to edit
          this.editInvoiceInfo = {
            invoiceNumber: invoice.invoiceNumber,
            isTaxableInvoice: invoice.totalTax > 0,
            invoiceBy: invoice.invoiceBy
          };
          // store few details again about customer for invoice preview in local storage
          //  this.stateManagerService.storeCustomer(this.customerDetail as ICustomer);
        }
      })
    ); // pipe
  }

  getSalesOrder(orderNo: number): Observable<IInvoice> {
    return this.invoiceService.getSalesOrder(orderNo).pipe(
      tap(invoice => {
        if (invoice) {
          this.lastInvoice = invoice;
          // store few details again about customer for invoice preview in local storage
          //  this.stateManagerService.storeCustomer(this.customerDetail as ICustomer);
        }
      })
    ); // pipe
  }

  getPayment(paymentNo: number): Observable<IPayment> {
    return this.paymentService.getPaymentDetail(paymentNo).pipe(
      tap(payment => {
        if (payment) {
          this.lastPayment = payment;
          // store few details again about customer for invoice preview in local storage
          //  this.stateManagerService.storeCustomer(this.customerDetail as ICustomer);
        }
      })
    ); // pipe
  }
}
