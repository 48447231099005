import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Anglar
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ErrorHandlerInterceptor } from '@otrack-lib/core/interceptors/error-interceptor.service';
import { PermissionDirective } from '@otrack-lib/directive/permission.directive';
import { IConfiguration } from '@otrack-lib/models/configuration.model';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { WINDOW_PROVIDERS } from './guards/window';
import { ConfigurationConfigService, ConfigurationService } from './helper-services/configuration.service';
import { LoadingInterceptor } from './interceptors/loader-interceptor.service';
import { CustomerOnlineAccessTypePipe } from './pipes/customer-user-type.pipe';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { GetObjectPipe } from './pipes/get-object.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { OrderTypeNamePipe } from './pipes/order-type.pipe';
import { PaymentMethodPipe } from './pipes/payment-method.pipe';
import { PermissionPipe } from './pipes/permission.pipe';
import { PosActionTypeNamePipe } from './pipes/pos-action-type.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TimeElapsedPipe } from './pipes/time-elapsed.pipe';
import { TobaccoCategoryNamePipe } from './pipes/tobacco-type.pipe';
import { DataStore } from './store';
import { AccountStore } from './store/data/accounts-store';
import { AccountEffects } from './store/data/accounts-store/account.effects';
import { DataEffects } from './store/data/data.effects';
import { SettingsStore } from './store/data/settings-store';
import { SettingsEffects } from './store/data/settings-store/settings.effects';



export const NB_CORE_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlerInterceptor,
    multi: true,
  },
  WINDOW_PROVIDERS,
  DatePipe,

];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(DataStore.dataFeatureKey, DataStore.reducer),
    StoreModule.forFeature(AccountStore.accountFeatureKey, AccountStore.reducer),
    StoreModule.forFeature(SettingsStore.settingsFeatureKey, SettingsStore.reducer),
    EffectsModule.forFeature([DataEffects, AccountEffects, SettingsEffects]),
    DeviceDetectorModule.forRoot()
  ],
  declarations: [
    TimeElapsedPipe,
    JoinPipe,
    PaymentMethodPipe,
    GetObjectPipe,
    OrderTypeNamePipe,
    PosActionTypeNamePipe,
    SafePipe,
    FirstLetterPipe,
    PermissionPipe,
    PermissionDirective,
    TobaccoCategoryNamePipe,
    CustomerOnlineAccessTypePipe
  ],
  exports: [
    TimeElapsedPipe,
    JoinPipe,
    PaymentMethodPipe,
    OrderTypeNamePipe,
    PosActionTypeNamePipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    PermissionPipe,
    PermissionDirective,
    TobaccoCategoryNamePipe,
    CustomerOnlineAccessTypePipe

  ],
  providers: [CookieService, PosActionTypeNamePipe]
})
export class CoreModule {

  static forRoot(config?: IConfiguration): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        ConfigurationService,
        {
          provide: ConfigurationConfigService,
          useValue: config,
        },
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
