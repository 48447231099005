import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { OrderTypeConvertor } from '@otrack-lib/enums';
import { ExportFormat } from '@otrack-lib/enums/exportformat.enum';
import { ITransaction, ITransactionList } from '@otrack-lib/models/order/transaction.model';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';
import { ReportFiltersModel } from '@otrack-lib/models/report-filters.model';
import { IAgingDetail, IAgingSummary } from '@otrack-lib/models/reports/aging-summary.model';
import { ICustomerItemSaleSummary } from '@otrack-lib/models/reports/customerItemSummary.model';
import { IItemQuickSummaryRpt } from '@otrack-lib/models/reports/item-quick-summary.model';
import { IOrderItemDetailSummary, IOrderItemSummary } from '@otrack-lib/models/reports/orderItemSummary.model';
import { IPaymentSummary } from '@otrack-lib/models/reports/paymentSummary.model';
import { IPurchaseItemDetailSummary, IPurchaseItemSummary } from '@otrack-lib/models/reports/purchaseItemSummary.model';
import { IStateReport } from '@otrack-lib/models/reports/state-report.model';
import { IStockSummaryRpt } from '@otrack-lib/models/reports/stock-summary.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEmailStatusModel } from './../../models/email/email.model';
import { IVendorAgingDetail, IVendorAgingSummary } from './../../models/reports/aging-summary.model';
import { IPaymentPayout, IPaymentPayoutDetail, IPaymentUnpaidPayout } from './../../models/reports/payment-payout.model';
import { IPOSTransactionSummary, IPOSTransactionTrail } from './../../models/reports/pos-transaction-summary,model';
import { IVendorItemSaleSummary } from './../../models/reports/vendorItemSummary.model';
import { ZReportModel } from './../../models/reports/z-report.model';
import { BaseApiService } from './baseapi.service';
import { TransactionReportService } from './transaction-report.service';


@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseApiService {
  readonly API_URLS = {
    orderitemSummaryReport: `${this.baseApiUrl}/v1/reports/orderitemsummary`,
    stockValuationReport: `${this.baseApiUrl}/v1/reports/inventory/valuation`,
    orderitemDetailSummaryReport: `${this.baseApiUrl}/v1/reports/orderitemdetail`,
    customerItemSaleSummaryReport: `${this.baseApiUrl}/v1/reports/customeritemsummary`,
    transactionSummaryReport: `${this.baseApiUrl}/v1/reports/transactionsummary`,
    vendorTransactionSummaryReport: `${this.baseApiUrl}/v1/reports/vendortransactionsummary`,
    paymentSummaryReport: `${this.baseApiUrl}/v1/reports/paymentreport`,
    paymentPayoutReport: `${this.baseApiUrl}/v1/payouts`,
    paymentPayoutSummaryReport: `${this.baseApiUrl}/v1/payouts/summary`,
    paymentPayoutUnpaidReport: `${this.baseApiUrl}/v1/payouts/unpaid`,
    agingSummaryReport: `${this.baseApiUrl}/v1/reports/agingsummary`,
    vendorAgingSummaryReport: `${this.baseApiUrl}/v1/reports/vendoragingsummary`,
    agingDetailReport: `${this.baseApiUrl}/v1/reports/agingdetail`,
    vendorAgingDetailReport: `${this.baseApiUrl}/v1/reports/vendoragingdetail`,
    transactionPagedHistoryReport: `${this.baseApiUrl}/v1/reports/transactionsummary/paginated`,
    profitLossReport: `${this.baseApiUrl}/v1/reports/profitloss`,
    public_transactionPagedHistoryReport: `${this.baseApiUrl}/v1/public/reports/transactionsummary/paginated`,
    // purchase reports url
    purchaseItemSummaryReport: `${this.baseApiUrl}/v1/reports/purchases/products`,
    vendorItemSummaryReport: `${this.baseApiUrl}/v1/reports/purchases/vendors`,
    stateReport: `${this.baseApiUrl}/v1/reports/orderitemsummary/tobacco`,
    zReport: `${this.baseApiUrl}/v1/pos/shifts`,
    posTxnTrailSummaryReport: `${this.baseApiUrl}/v1/pos/trail/posTransactionSummary`,
    posTranSummaryReport: `${this.baseApiUrl}/v1/pos/transactionSummary`,
    emailStatusReport: `${this.baseApiUrl}/v1/email/status`
  };

  constructor(_injector: Injector) {
    super(_injector);
  }

  static getOrderItemSummaryModel(res: any): IOrderItemSummary {
    return {
      productId: +res['productId'],
      productName: res['productName'],
      description: res['description'],
      barcode: res['barcode'],
      quantity: res['quantity'],
      amount: res['amount'],
      cogs: res['cogs']
    };
  }

  static getPosTxnTrailModel(res: any): IPOSTransactionTrail {
    return {
      transactionDate: res['createdDate'],
      modifiedDate: res['modifiedDate'],
      createdByUserId: +res['createdByUserId'],
      createdUserName: res['createdUserName'],
      createdUserFirstName: res['createdUserFirstName'],
      lineNo: res['lineNo'] ? +res['lineNo'] : 0,
      shiftId: +res['shiftId'],
      sequenceNumber: res['sequenceNumber'],
      transactionType: res['transactionType'],
      actionType: res['actionType'],
      status: res['status'],
      customerId: res['customerId'] ? +res['customerId'] : 0,
      customerName: res['customerName'],
      customerCompanyName: res['customerCompanyName'],
      productId: res['productId'] ? +res['productId'] : 0,
      productName: res['productName'],
      productDescription: res['productDescription'],
      quantity: +res['quantity'],
      oldQuantity: +res['oldQuantity'],
      price: res['price'] ? +res['price'] : -1,
      oldPrice: res['oldPrice'] ? +res['oldPrice'] : -1,
      taxable: res['taxable'],
      salesOrderId: +res['salesOrderId'],
      totalEntries: +res['totalEntries'],
      description: res['description']
    };
  }

  static getPosTranSummaryModel(res: any): IPOSTransactionSummary {
    return {
      id: +res['id'],
      invoiceNumber: res['invoiceNumber'],
      shiftId: +res['shiftId'],
      shiftNumber: res['shiftNumber'],
      transactionDate: res['date'],
      createdDate: res['createdDate'],
      customerId: +res['customerId'],
      customerName: res['customerName'],
      customerCompanyName: res['customerCompanyName'],
      amount: +res['totalAmount'],
      totalTax: +res['totalTax'],
      onAccountAmount: +res['onAccountAmount'],
      cashAmount: +res['cashAmount'],
      creditAmount: +res['creditAmount'],
      chequeAmount: +res['chequeAmount'],
    };
  }


  static getEmaiLStatusModel(res: any): IEmailStatusModel {
    return {
      emailId: res['createdDate'],
      createdDate: res.createdDate,
      createdBy: res.createdBy,
      toEmail: res.toEmail,
      ccEmail: res.toEmail,
      bccEmail: res.bccEmail,
      subject: res.subject,
      messageId: res.messageId,
      sendAt: res.sendAt,
      rejectedAt: res.rejectedAt,
      deliveredAt: res.deliveredAt,
      failedAt: res.failedAt,
      openedAt: res.openedAt,
      clickedAt: res.clickedAt,
      fromEmail: res.fromEmail,
      fromName: res.fromName,
      replyToEmail: res.replyToEmail,
      emailTemplate: res.emailTemplate,
      status: res.status
    };
  }




  static getPurchaseItemSummaryModel(res: any): IPurchaseItemSummary {
    return {
      productId: +res['productId'],
      productName: res['productName'],
      description: res['description'],
      barcode: res['barcode'],
      quantity: res['quantity'],
      amount: res['amount'],
    };
  }

  static getStockSummaryRptModel(res: any): IStockSummaryRpt {
    return {
      productId: +res['productId'],
      categoryName: res['categoryName'],
      productName: res['productName'],
      description: res['description'],
      barcode: res['barcode'],
      quantity: res['quantity'],
      averageCost: res['averageCost'],
      value: res['value'],
    };
  }

  static getItemQuickSummaryRptModel(res: any): IItemQuickSummaryRpt {
    return {
      date: res.date,
      description: res.description,
      billId: res.billId,
      inventoryAdjustmentId: res.inventoryAdjustmentId,
      orderId: res.orderId,
      name: res.name,
      quantity: res.quantity,
      unitCost: res.unitCost,
      onHand: res.onHand,
      assetValue: res.assetValue,
      averageCost: res.averageCost,
    };
  }

  static getCustomerItemSummaryModel(res: any): ICustomerItemSaleSummary {
    return {
      customerId: +res['customerId'],
      customerCompanyName: res['customerCompanyName'],
      customerName: res['customerName'],
      quantity: res['quantity'],
      totalTax: res['totalTax'],
      netTotal: res['netTotal'],
      subTotal: res['subTotal'],
      cogs: res['cogs']
    };
  }


  static getVendorItemSummaryModel(res: any): IVendorItemSaleSummary {
    return {
      vendorId: +res['vendorId'],
      vendorCompanyName: res['vendorCompanyName'],
      vendorName: res['vendorName'],
      quantity: res['quantity'],
      totalTax: res['totalTax'],
      netTotal: res['netTotal'],
      subTotal: res['subTotal']
    };
  }

  static getPaymentSummaryModel(res: any): IPaymentSummary {
    return {
      paymentId: +res['paymentId'],
      creationDate: res['creationDate'],
      chequeNo: res['chequeNo'],
      paymentDate: res['paymentDate'],
      customerId: res['customerId'],
      customerName: res['customerName'],
      paymentAmount: res['paymentAmount'],
      unusedAmount: res['unusedAmount'],
      paymentNumber: res['paymentNumber'],
      paymentType: res['paymentType'],
      paymentBy: res['paymentBy'],
      expanded: res['expanded']
    };
  }

  static getPaymentPayoutModel(res: any): IPaymentPayout {
    return {
      id: res.id,
      externalTotalTransactions: res.totalTransactions,
      payoutAmount: res.payoutAmount,
      externalTotalApplicationFeeAmount: res.totalApplicationFeeAmount,
      externalTotalStripeFeeAmount: res.totalStripeFeeAmount,
      externalTotalAmount: res.totalAmount,
      availableDate: res.availableDate,
      expanded: false,
      status: res.status,
      internalTotalTransactions: res.internalData ? res.internalData.totalTransactions : null,
      internalTotalAmount: res.internalData ? res.internalData.totalAmount : null
    };
  }

  static getPaymentUnpaidPayoutModel(res: any): IPaymentUnpaidPayout {
    return {
      paymentId: res.paymentId,
      paymentNumber: res.paymentNumber,
      orderId: res.orderId,
      orderNumber: res.orderNumber,
      customerId: res.customerId,
      customerName: res.customerName,
      chargeDate: res.chargeDate,
      amount: res.amount,
      createdByUser: res.createdByUser
    };
  }

  static getPaymentPayoutDetailModel(res: any): IPaymentPayoutDetail {
    const output: IPaymentPayoutDetail = {
      id: res.id,
      externalChargeDate: res.chargeDate,
      externalAmount: res.amount,
      externalApplicationFeeAmount: res.applicationFeeAmount,
      externalStripeFeeAmount: res.stripeFeeAmount,
      externalNetAmount: res.netAmount,
    };

    if (res.internalData) {
      output.internalPaymentId = res.internalData.paymentId;
      output.internalPaymentNumber = res.internalData.paymentNumber;
      output.orderId = res.internalData.orderId;
      output.orderNumber = res.internalData.orderNumber;
      output.customerId = res.internalData.customerId;
      output.customerName = res.internalData.customerName;
      output.createdByUser = res.internalData.createdByUser;
      output.internalAmount = res.internalData.amount;
      output.internalApplicationFeeAmount = res.internalData.applicationFeeAmount;
      output.internalChargeDate = res.internalData.chargeDate;
    }

    return output;
  }

  static getOrderItemDetailSummaryModel(res: any): IOrderItemDetailSummary {
    const result = this.getOrderItemSummaryModel(res) as IOrderItemDetailSummary;
    result.orderId = +res['orderId'];
    result.orderDate = res['orderDate'];
    result.orderNumber = res['orderNumber'];
    result.customerName = res['customerName'];
    result.companyName = res['companyName'];
    result.salePrice = res['salePrice'];
    result.cost = res['cost'];
    return result;
  }

  static getPurchaseItemDetailSummaryModel(res: any): IPurchaseItemDetailSummary {
    const result = this.getPurchaseItemSummaryModel(res) as IPurchaseItemDetailSummary;
    result.billId = +res['billId'];
    result.billDate = res['billDate'];
    result.billNumber = res['billNumber'];
    result.referenceNumber = res['referenceNumber'];
    result.vendorName = res['vendorName'];
    result.companyName = res['companyName'];
    result.cost = res['cost'];
    result.subTotal = res['subTotal'];
    result.totalTax = res['totalTax'];
    result.billType = res['billType'];
    return result;
  }


  static getAgingSummaryModel(res: any): IAgingSummary {
    return {
      customerId: res['personId'],
      customerName: res['personName'],
      current: +res['current'],
      oneToThirty: +res['oneToThirty'],
      thirtyOneToSixty: +res['thirtyOneToSixty'],
      sixtyOneToNinety: +res['sixtyOneToNinety'],
      moreThanNinety: +res['moreThanNinety'],
      total: +res['total']
    };
  }

  static getVendorAgingSummaryModel(res: any): IVendorAgingSummary {
    return {
      vendorId: res['personId'],
      vendorName: res['personName'],
      current: +res['current'],
      oneToThirty: +res['oneToThirty'],
      thirtyOneToSixty: +res['thirtyOneToSixty'],
      sixtyOneToNinety: +res['sixtyOneToNinety'],
      moreThanNinety: +res['moreThanNinety'],
      total: +res['total']
    };
  }

  static getAgingDetailModel(res: any): IAgingDetail {
    return {
      agingGroup: res['agingGroup'],
      orderType: res['orderType'],
      orderId: res['orderId'],
      orderDate: res['orderDate'],
      invoiceNumber: res['invoiceNumber'],
      totalAmount: +res['totalAmount'],
      aging: +res['aging'],
      amountDue: +res['amountDue']
    };
  }

  static getVendorAgingDetailModel(res: any): IVendorAgingDetail {
    return {
      agingGroup: res['agingGroup'],
      orderType: res['orderType'],
      orderId: res['billId'],
      orderDate: res['billDate'],
      billNumber: res['billNumber'],
      totalAmount: +res['totalAmount'],
      aging: +res['aging'],
      amountDue: +res['amountDue']
    };
  }



  static getZReportModel(res: any): ZReportModel {
    return {
      shiftId: res.shiftId,
      sequenceNumber: res.sequenceNumber,
      startedByUserId: res.startedByUserId,
      startedByUserName: res.startedBy,
      closedByUserId: res.closedByUserId,
      closedByUserName: res.closedBy,
      startingCash: res.startingCash,
      closingCash: res.closingCash,
      shiftStartDate: res.startTime,
      shiftEndDate: res.closeTime
    };
  }

  static getTransactionModelFromPageResult(res: any): ITransaction {
    return {
      customerName: res.customerName ? res.customerName : res.vendorName,
      companyName: res.customerCompanyName ? res.customerCompanyName : res.vendorCompanyName,
      customerId: res.customerId ? res.customerId : res.vendorId,
      id: +res['id'],
      transaction: res['transaction'],
      transactionType: +res['transactionType'],
      transactionNumber: res['transactionNumber'],
      transactionBy: res['createdBy'],
      modifiedDate: res['modifiedDate'],
      date: res['date'],
      amount: +res['amount'],
      amountDue: +res['balance'],
      isSynced: res['isSynced'],
      memo: res['memo'],
      paymentMethod: +res['paymentMethod'],
      status: res['salesOrderStatus'],
      pickupDate: res['pickupDate'],
      pickupTime: res['pickupTime'],
      deliveryType: res['deliveryMethod'],
      referenceNumber: res['referenceNumber'],
    };
  }

  static getStateReportModel(res: any): IStateReport {
    const report: IStateReport = {
      companyTobaccoPermitId: res['companyTobaccoPermitId'],
      customerId: +res['customerId'],
      customerCompanyName: res['customerCompanyName'],
      customerName: res['customerName'],
      salesTaxId: res['salesTaxId'],
      street: res['street'],
      city: res['city'],
      state: res['state'],
      postalCode: res['postalCode'],
      tobaccoPermitId: res['tobaccoPermitId'],
      tobaccoUnitOfMeasure: res['tobaccoUnitOfMeasure'],
      tobaccoCategoryType: res['tobaccoCategoryType'],
      quantity: res['quantity'],
      amount: res['amount'],
      errors: res['errors']
    };

    return report;
  }

  getOrderItemSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.orderitemSummaryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.orderitemSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IOrderItemSummary[] = [];
        for (const item of res) {
          output.push(ReportService.getOrderItemSummaryModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getEmailStatusReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    return this.httpAuthPost(
      `${this.API_URLS.emailStatusReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IEmailStatusModel[] = [];
        if (res && res.data && res.pagination) {
          for (const item of res.data) {
            output.push(ReportService.getEmaiLStatusModel(item));
          }
          return new QueryResultsModel(output, res.pagination.total);
        }
        return new QueryResultsModel([], 0);
      })
    );
  }

  getPOSTxnTrailSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    return this.httpAuthPost(
      `${this.API_URLS.posTxnTrailSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IPOSTransactionTrail[] = [];
        if (res && res.data && res.pagination) {
          for (const item of res.data) {
            output.push(ReportService.getPosTxnTrailModel(item));
          }
          return new QueryResultsModel(output, res.pagination.total);
        }
        return new QueryResultsModel([], 0);
      })
    );
  }

  getPOSTranSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    return this.httpAuthPost(
      `${this.API_URLS.posTranSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IPOSTransactionSummary[] = [];
        if (res && res.data && res.pagination) {
          for (const item of res.data) {
            output.push(ReportService.getPosTranSummaryModel(item));
          }
          const model = new QueryResultsModel(output, res.pagination.total);
          model.additionalFields = res.sumData;
          return model
        }
        return new QueryResultsModel([], 0);
      })
    );
  }

  getPosTxnTrailLineNoSummary(lineNo: number): Observable<IPOSTransactionTrail[]> {
    return this.httpAuthGet(
      `${this.API_URLS.posTxnTrailSummaryReport}/${lineNo}`).pipe(
        map(res => {
          const output: IPOSTransactionTrail[] = [];
          if (res && res.data) {
            for (const item of res.data) {
              output.push(ReportService.getPosTxnTrailModel(item));
            }
            return output;
          }
          return [];
        })
      );
  }

  getPurchaseItemSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.purchaseItemSummaryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.purchaseItemSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IPurchaseItemSummary[] = [];
        for (const item of res.data) {
          output.push(ReportService.getPurchaseItemSummaryModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getStockSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.stockValuationReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.stockValuationReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IStockSummaryRpt[] = [];
        for (const item of res) {
          const st = ReportService.getStockSummaryRptModel(item);
          output.push(st);
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getItemQuickSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    const productId = (reportFilter.productFilterList && reportFilter.productFilterList.length > 0)
      ? reportFilter.productFilterList[0] : -1;
    const date = moment(reportFilter.toDate).format(moment.HTML5_FMT.DATE);
    const url = `${this.API_URLS.stockValuationReport}/${productId}?toDate=${date}`;
    if (reportFilter.exportFormat) {
      return this.httpAuthGet(
        url,
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthGet(url).pipe(
      map(res => {
        const output: IItemQuickSummaryRpt[] = [];
        for (const item of res.data) {
          const st = ReportService.getItemQuickSummaryRptModel(item);
          output.push(st);
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getCustomerItemSaleSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.customerItemSaleSummaryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.customerItemSaleSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: ICustomerItemSaleSummary[] = [];
        for (const item of res) {
          output.push(ReportService.getCustomerItemSummaryModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getVendorItemSaleSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.vendorItemSummaryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.vendorItemSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IVendorItemSaleSummary[] = [];
        for (const item of res.data) {
          output.push(ReportService.getVendorItemSummaryModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }


  getOrderItemDetailSummaryReport(
    productId: number,
    reportFilter: ReportFiltersModel
  ): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.orderitemDetailSummaryReport}/${productId}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.orderitemDetailSummaryReport}/${productId}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IOrderItemDetailSummary[] = [];
        for (const item of res) {
          output.push(ReportService.getOrderItemDetailSummaryModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getPuchaseItemDetailSummaryReport(
    productId: number,
    reportFilter: ReportFiltersModel
  ): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.purchaseItemSummaryReport}/${productId}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.purchaseItemSummaryReport}/${productId}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IPurchaseItemDetailSummary[] = [];
        for (const item of res.data) {
          output.push(ReportService.getPurchaseItemDetailSummaryModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getTransactionSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.transactionSummaryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.transactionSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: ITransactionList[] = [];
        for (const item of res) {
          // output.push(ReportService.getOrderItemSummaryModel(item));
          output.push(TransactionReportService.getTransactionModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getVendorTransactionSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.vendorTransactionSummaryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.vendorTransactionSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: ITransactionList[] = [];
        for (const item of res) {
          // output.push(ReportService.getOrderItemSummaryModel(item));
          output.push(TransactionReportService.getTransactionModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  private getExportRequestOptions(exportFormat: ExportFormat) {
    return {
      responseType: 'blob',
      observe: 'response',
      params: new HttpParams().set('export', ExportFormat[exportFormat].toLowerCase())
    }
  }

  getZReports(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.zReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.zReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: ZReportModel[] = [];
        if (res && res.data && res.data.records) {
          for (const item of res.data.records) {
            // output.push(ReportService.getOrderItemSummaryModel(item));
            output.push(ReportService.getZReportModel(item));
          }
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }


  getPaymentSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.paymentSummaryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.paymentSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IPaymentSummary[] = [];
        for (const item of res) {
          output.push(ReportService.getPaymentSummaryModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getPaymentPayoutReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.paymentPayoutSummaryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(`${this.API_URLS.paymentPayoutSummaryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IPaymentPayout[] = [];
        for (const item of res.data) {
          output.push(ReportService.getPaymentPayoutModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getPaymentPayoutUnpaidReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.paymentPayoutUnpaidReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(`${this.API_URLS.paymentPayoutUnpaidReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IPaymentUnpaidPayout[] = [];
        for (const item of res.data) {
          output.push(ReportService.getPaymentUnpaidPayoutModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getPaymentPayoutDetailReport(id?: number): Observable<QueryResultsModel> {
    const url = `${this.API_URLS.paymentPayoutReport}/${id}`;
    return this.httpAuthGet(url).pipe(
      map(res => {
        const output: IPaymentPayoutDetail[] = [];
        for (const item of res.data) {
          output.push(ReportService.getPaymentPayoutDetailModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getAgingSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.agingSummaryReport}`,
        ReportFiltersModel.withToDate(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.agingSummaryReport}`,
      ReportFiltersModel.withToDate(reportFilter)
    ).pipe(
      map(res => {
        const output: IAgingSummary[] = [];
        for (const item of res) {
          output.push(ReportService.getAgingSummaryModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getAgingDetailReport(customerId: number, reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.agingDetailReport}/${customerId}`,
        ReportFiltersModel.withToDate(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.agingDetailReport}/${customerId}`,
      ReportFiltersModel.withToDate(reportFilter)
    ).pipe(
      map(res => {
        const output: IAgingDetail[] = [];
        for (const item of res) {
          output.push(ReportService.getAgingDetailModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getVendorAgingSummaryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.vendorAgingSummaryReport}`,
        ReportFiltersModel.withToDate(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.vendorAgingSummaryReport}`,
      ReportFiltersModel.withToDate(reportFilter)
    ).pipe(
      map(res => {
        const output: IVendorAgingSummary[] = [];
        for (const item of res) {
          output.push(ReportService.getVendorAgingSummaryModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  getVendorAgingDetailReport(vendorId: number, reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.vendorAgingDetailReport}/${vendorId}`,
        ReportFiltersModel.withToDate(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.vendorAgingDetailReport}/${vendorId}`,
      ReportFiltersModel.withToDate(reportFilter)
    ).pipe(
      map(res => {
        const output: IVendorAgingDetail[] = [];
        for (const item of res) {
          output.push(ReportService.getVendorAgingDetailModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }


  // This method is used in client-side to load invoice by filter
  getPagedTransactionHistoryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.transactionPagedHistoryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.transactionPagedHistoryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: ITransaction[] = [];
        for (const item of res.data) {
          output.push(ReportService.getTransactionModelFromPageResult(item));
        }
        return new QueryResultsModel(output, res.pagination.total);
      })
    );
  }

  // This API is use in CustomerAPP
  getPublicPagedTransactionHistoryReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.public_transactionPagedHistoryReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.public_transactionPagedHistoryReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: ITransaction[] = [];
        for (const item of res.data) {
          output.push(ReportService.getTransactionModelFromPageResult(item));
        }
        return new QueryResultsModel(output, res.pagination.total);
      })
    );
  }

  getProfitLossReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.profitLossReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.profitLossReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    );
  }

  getStateReport(reportFilter: ReportFiltersModel): Observable<QueryResultsModel> {
    if (reportFilter.exportFormat) {
      return this.httpAuthPost(
        `${this.API_URLS.stateReport}`,
        ReportFiltersModel.withDateRange(reportFilter),
        this.getExportRequestOptions(reportFilter.exportFormat)
      );
    }
    return this.httpAuthPost(
      `${this.API_URLS.stateReport}`,
      ReportFiltersModel.withDateRange(reportFilter)
    ).pipe(
      map(res => {
        const output: IStateReport[] = [];
        for (const item of res.data) {
          output.push(ReportService.getStateReportModel(item));
        }
        return new QueryResultsModel(output, output.length);
      })
    );
  }

  exportStateReport(reportFilter: ReportFiltersModel): Observable<any> {
    const format = ExportFormat[reportFilter.exportFormat];
    return this.httpAuthPost(
      `${this.API_URLS.stateReport}?export=${format.toLowerCase()}`,
      reportFilter,
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
  }
}
