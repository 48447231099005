
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './customer.actions';
import { ErrorModel } from '@otrack-lib/models/error.model';

export const currentCustomerKey = 'customer';

export interface CustomerState {
   currentCustomer: ICustomer;
   error: ErrorModel;
   success: boolean;
   loading: boolean;
}

export const initialState: CustomerState = {
  currentCustomer: null,
  error: null,
  loading: false,
  success: false,
};

const Reducer = createReducer(
  initialState,
  on(Actions.setCurrentCustomer, (state, {data}) => ({...state, currentCustomer: data,  loading: false, success: true})),
  on(Actions.loadCurrentCustomer, state => ({...state, loading: true, success: false})),
  on(Actions.loadCurrentCustomerSuccess, (state, {data}) => ({...state, currentCustomer: data, success: true, loading: false })),
  on(Actions.loadCurrentCustomerFailure, (state, { error }) => ({...state, error, loading: false, success: false})),
  on(Actions.clearCurrentCustomer, ()  => ({currentCustomer: null,    error: null,    loading: false,    success: false})),
);

export function reducer(state: CustomerState | undefined, action: Action) {
  return Reducer(state, action);
}
