import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as VendorStore from './vendor.reducer';

export const selectState = createFeatureSelector<VendorStore.VendorState>(
  VendorStore.currentVendorKey
);


export const getCurrentVendorId = createSelector(
  selectState,
  (state: VendorStore.VendorState) => {
    return state.currentVendor ? state.currentVendor.vendorId : undefined;
  }
);

export const getCurrentVendor = createSelector(
  selectState,
  (state: VendorStore.VendorState) => {
    return state.currentVendor ? state.currentVendor : undefined;
  }
);

export const isVendorSelected = createSelector(
  selectState,
  (state: VendorStore.VendorState) => {
    return state && state.currentVendor  ? true : false;
  }
);

export const getLoadingStatus = createSelector(
  selectState,
  (state: VendorStore.VendorState) => state ? state.loading : false
);


export const getVendorSuccessStatus = createSelector(
  selectState,
  (state: VendorStore.VendorState) => state ? state.success : false
);
