import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subscription, Observable } from 'rxjs';
import { InMemoryService } from '@client-app/services/inMemoryinvoice.service';
import { OperationType } from '@client-app/common/models/operationtype.enum';
/**
 * @title Input with a clear button
 */
@Component({
    selector: 'app-input-quantity',
    templateUrl: './quantityInput.component.html',
    styleUrls: ['./quantityInput.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputQuantityComponent),
            multi: true
        }
    ]
})
export class InputQuantityComponent implements OnInit, ControlValueAccessor {
    value = 0;
    @Input() size = 3;
    @Input() minibox = false;
    qtyInputBox: HTMLElement;

    /**
     * Invoked when the model has been changed
     */
    onChange: (_: any) => void = (_: any) => {};
    onKey(event: any) {
        // without type info
        this.updateChanges();
        // this.values += event.target.value + ' | ';
        // //consol.log(event.target.value);
    }

    constructor(public _inMemoryService: InMemoryService) {}

    ngOnInit(): void {}

    incrementValue() {
        if (this.value === undefined) {
            this.value = 0;
        }
        this.value = this.value + 1;
        this.updateChanges();
    }

    decrementValue() {
        if (this.value === undefined) {
            this.value = 0;
        }
        if (this._inMemoryService.currentOperation === OperationType.CreditMemo && this.value === 0) {
            this.updateChanges(); // no negative allow on CreditMemo
            return;
        }
        this.value = this.value - 1;
        this.updateChanges();
    }

    clearValue() {
        this.value = 0;
        this.updateChanges();
    }

    clearValueIfZero() {
        if (this.value === 0) {
            this.value = undefined;
        }
    }
    /**
     * Invoked when the model has been touched
     */
    onTouched: () => void = () => {};

    /**
     * Method that is invoked on an update of a model.
     */
    updateChanges() {
        // // //consol.log('Change');
        this.onChange(this.value);
    }

    /**
     * Writes a new item to the element.
     * @param value the value
     */
    writeValue(value: number): void {
        this.value = value;
        this.updateChanges();
    }

    /**
     * Registers a callback function that should be called when the control's value changes in the UI.
     * @param fn
     */
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that should be called when the control receives a blur event.
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
