import { createAction, props } from '@ngrx/store';
import { ICustomer } from '@otrack-lib/models/customer/customer.model';
// current not loading whole customer list in state


export const setCurrentCustomer = createAction(
  '[Customer]  Set Current Customer',
  props<{ data: ICustomer}>()
);

export const loadCurrentCustomer = createAction(
  '[Customer]  load Current Customer',
  props<{ data: number}>()
);

export const loadCurrentCustomerSuccess = createAction(
  '[Customer]  Load Current Customer Success',
  props<{ data: ICustomer}>()
);

export const loadCurrentCustomerFailure = createAction(
  '[Customer]  Load Current Customer Failure',
  props<{ error: any}>()
);

export const clearCurrentCustomer = createAction(
  '[Customer]  Clear Current Customer'
);
