import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxBarcodeModule } from 'ngx-barcode';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { IResponsiveConfig, ResponsiveModule } from 'ngx-responsive';
import { ConfirmationService, ConfirmDialogModule, MultiSelectModule } from 'primeng/primeng';
import { BarcodeGeneratorComponent } from './component/barcode-generator/barcode-generator.component';
import { CustomerListItemComponent } from './component/customer-list-panel/customer-list-item/customer-list-item.component';
import { CustomerListPanelComponent } from './component/customer-list-panel/customer-list-panel.component';
import { DesktopviewHeaderComponent } from './component/main-header/desktopview-header/desktopview-header.component';
import { MainHeaderComponent } from './component/main-header/main-header.component';
import { MobileviewHeaderComponent } from './component/main-header/mobileview-header/mobileview-header.component';
import { MainLayoutComponent } from './component/main-layout/main-layout.component';
import { InputQuantityComponent } from './component/quantityInput/quantityInputcomponent';
import { ClientReportFilterComponent } from './component/report-filter/report-filter.component';
import { SideFooterMenuComponent } from './component/side-footer-menu/side-footer-menu.component';
import { TransactionListItemComponent } from './component/transaction-list-item/transaction-list-item.component';
import { VendorListItemComponent } from './component/vendor-list-panel/vendor-list-item/vendor-list-item.component';
import { VendorListPanelComponent } from './component/vendor-list-panel/vendor-list-panel.component';
import {
  CustomerFilterPipe, DataFilterPipe,
  FrontMenenuMobileViewFilterPipe, HiddenMenuFilterPipe,
  HideMenenuMobileViewFilterPipe, TruncatePipe,
  VendorFilterPipe
} from './elements/data-filter.pipe';
import { PagingPipe } from './elements/paging.pipe';
import { HeaderDesktopComponent } from './header/header-desktop.component';
import { HeaderTabletComponent } from './header/header-tablet.component';
import { MaterialModule } from './material/material.module';
import { SessionRestoreDialog } from './model/session-restore.dialog';
import { PreLoaderComponent } from './pre-loader/pre-loader.component';
import { PreMiddleScreenLoaderComponent } from './pre-loader/pre-middle-loader.component';
import { SpinnerComponent } from './spinner/spinner.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// Reduced debounceTime for NG Responsive package
const ResponsiveSettings: IResponsiveConfig = {
  breakPoints: {
    xs: { max: 575 }, 				// xs: { max: 767 },
    sm: { min: 576, max: 767 }, 	// sm: { min: 768, max: 991 },
    md: { min: 768, max: 991 }, 	// md: { min: 992, max: 1199 },
    lg: { min: 992, max: 1199 }, 	// lg: { min: 1200, max: 1599 },
    xl: { min: 1200 } 				// xl: { min: 1600 }
  },
  debounceTime: 1 // allow to debounce checking timer
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {
  thousandSeparator: ',',
  separatorLimit: '10000',
  // validation: true
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // tslint:disable-next-line: deprecation
    NgbModule,
    ResponsiveModule.forRoot(ResponsiveSettings),
    HttpClientModule,
    PerfectScrollbarModule,
    ConfirmDialogModule,
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgxBarcodeModule,
    MultiSelectModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    NgbModule,
    NgxMaskModule,
    ConfirmDialogModule,
    HttpClientModule,
    PerfectScrollbarModule,
    PreLoaderComponent,
    PreMiddleScreenLoaderComponent,
    SpinnerComponent,
    HeaderTabletComponent,
    SideFooterMenuComponent,
    MainLayoutComponent,
    HeaderDesktopComponent,
    BarcodeGeneratorComponent,
    CustomerListPanelComponent,
    CustomerListItemComponent,
    VendorListPanelComponent,
    VendorListItemComponent,
    MaterialModule,
    ResponsiveModule,
    DataFilterPipe,
    CustomerFilterPipe,
    VendorFilterPipe,
    TruncatePipe,
    HiddenMenuFilterPipe,
    FrontMenenuMobileViewFilterPipe,
    HideMenenuMobileViewFilterPipe,
    TypeaheadModule,
    BsDropdownModule,
    TabsModule,
    ModalModule,
    PagingPipe,
    SweetAlert2Module,
    InputQuantityComponent,
    ClientReportFilterComponent
  ],
  declarations: [
    BarcodeGeneratorComponent,
    ClientReportFilterComponent,
    SessionRestoreDialog,
    InputQuantityComponent,
    SpinnerComponent,
    DataFilterPipe,
    CustomerFilterPipe,
    VendorFilterPipe,
    TruncatePipe,
    HiddenMenuFilterPipe,
    FrontMenenuMobileViewFilterPipe,
    HideMenenuMobileViewFilterPipe,
    PagingPipe,
    PreLoaderComponent,
    PreMiddleScreenLoaderComponent,
    HeaderTabletComponent,
    HeaderDesktopComponent,
    TransactionListItemComponent,
    CustomerListPanelComponent,
    CustomerListItemComponent,
    VendorListPanelComponent,
    VendorListItemComponent,
    SideFooterMenuComponent,
    MainLayoutComponent,
    MobileviewHeaderComponent,
    DesktopviewHeaderComponent,
    MainHeaderComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ConfirmationService
  ],
  entryComponents: [InputQuantityComponent, SessionRestoreDialog],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
