import { Injectable } from '@angular/core';
import { OrderTypes } from '@otrack-lib/enums';
import { InvoiceTemplateType } from '@otrack-lib/models/company/company.model';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';


@Injectable({
  providedIn: 'root'
})
export class PrintService {
  public isPrinting = false;
  public documentData: any;
  private documentName = '';
  private isSmallReceipt = false;
  private orderType = OrderTypes;
  private deviceInfo: DeviceInfo = null;

  constructor(
    private shareService: NgNavigatorShareService,
    private deviceService: DeviceDetectorService
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }
  printDocument(documentName: string, documentData: any[]) {
    this.documentData = documentData;
    this.documentName = documentName;
    this.print();
  }

  // tslint:disable-next-line: cyclomatic-complexity
  print() {
    if (
      this.documentName === 'invoice' ||
      this.documentName === 'invoice2' ||
      this.documentName === InvoiceTemplateType.DetailTemplate.toString() ||
      this.documentName === InvoiceTemplateType.ItemCodeInvoiceTemplate.toString() ||
      this.documentName === InvoiceTemplateType.OrderPullTemplate ||
      this.documentName === 'salesorder' ||
      this.documentName === 'creditmemo' ||
      this.documentName === 'purchaseorder' ||
      this.documentName === 'purchasebill' ||
      this.documentName === 'template'
    ) {
      this.printInvoice();
    } else if (this.documentName === 'payment') {
      this.printPayment();
    } else if (this.documentName === 'billpayment') {
      this.printBillPayment();
    } else if (this.documentName === 'receipt') {
      this.printReceipt();
    } else if (this.documentName === 'shippingslip') {
      this.printShippingSlip();
    } else if (
      this.documentName === 'transactionreport' ||
      this.documentName === 'itemorderreport' ||
      this.documentName === 'stocksummaryreport' ||
      this.documentName === 'agingreport' ||
      this.documentName === 'vendoragingreport' ||
      this.documentName === 'paymentreport' ||
      this.documentName === 'customeritemsalereport' ||
      this.documentName === 'itembypurchasereport' ||
      this.documentName === 'vendoritempurchasereport' ||
      this.documentName === 'itemdetailreport' ||
      this.documentName === 'openbalancereport' ||
      this.documentName === 'zreport' ||
      this.documentName === 'zreportlist'
    ) {
      this.printReports();
    }
  }

  printInvoice() {
    const id = this.documentData[0].orderId;
    const withBarcode: boolean = this.documentData[1]
      ? this.documentData[1]
      : false;

    // this could be invoice/salesorder/creditmemo/purchasebill/purchaseorder
    const orderType = this.documentData[0].orderType.toString();
    window.open(
      `/print/${this.documentName}/${orderType},${id},${withBarcode}`
    );
  }

  printShippingSlip() {
    const id = this.documentData[0].orderId;
    const withBarcode: boolean = this.documentData[1]
      ? this.documentData[1]
      : false;

    // this could be invoice/salesorder/creditmemo/purchasebill/purchaseorder
    const orderType = this.documentData[0].orderType.toString();
    window.open(
      `/print/${this.documentName}/${orderType},${id},${withBarcode}`
    );
  }

  printPayment() {
    const id = this.documentData[0].paymentId;
    window.open(`/print/${this.documentName}/3,${id}`);
  }

  printBillPayment() {
    const id = this.documentData[0].paymentId;
    window.open(`/print/${this.documentName}/8,${id}`);
  }

  printReceipt() {
    const token = this.documentData[1];
    let orderType = '-1';

    if (this.documentData[0].orderType === this.orderType.SalesOrder) {
      orderType = this.orderType.SalesOrder.toString();
    } else {
      orderType = this.orderType.Invoice.toString();
    }

    // check if this is ios device, then use scheme for deeplink
    if (this.deviceInfo.os === 'iOS' || (this.deviceInfo.os === 'Mac' && this.deviceService.isTablet())) {
      const url = `otrackprint://?type=${orderType}&token=${token}&id=${this.documentData[0].orderId}`;
      window.open(url);
    } else {
      // FIXME: use actual document name, when app start supporting all the receipt types
      const url = `/print/invoice/${orderType},${token},${this.documentData[0].orderId}`;

      if (!this.shareService.canShare()) {
        window.open(url);
      }
      else {
        this.shareService.share({
          title: 'Otrack.io',
          url
        }).then((response) => {
          // console.log(response);
        }).catch((error) => {
          console.log(error);
          // incase the share doesn't work then we continue doing it in old way
          window.open(url);
        });
      }
    }
  }

  printReceipt2() {
    const token = this.documentData[1];
    let orderType = '-1';

    if (this.documentData[0].orderType === this.orderType.Invoice) {
      orderType = this.orderType.Invoice.toString();
    } else {
      orderType = this.orderType.SalesOrder.toString();
    }
  }

  printTransactionReport() {
    const pageNumber = this.documentData[0];
    const pageSize = this.documentData[1];
    const searchText = this.documentData[2];
    const orderType = this.documentData[3];
    const taxableType = this.documentData[4];
    const activeType = this.documentData[5];
    const qbCustomerType = this.documentData[6];
    const fromDate = this.documentData[7];
    const toDate = this.documentData[8];

    const url = `/print/${this.documentName}/${pageNumber},${pageSize},${searchText},${orderType},${taxableType},${activeType},${qbCustomerType},${fromDate},${toDate}`;
    window.open(url);
  }

  printReports() {
    localStorage.setItem('report_print', JSON.stringify(this.documentData[0]));
    const url = `/print/${this.documentName}`;
    window.open(url);
  }

  onDataReady() {
    /*window.print();
     setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null }}]);
    }); */

    setTimeout(() => {
      window.focus();
      window.print();
      this.isPrinting = false;
      window.close();
    }, 500);
  }
}
