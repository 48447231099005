import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  RouterEvent,
  NavigationError
} from '@angular/router';
import { PrintService } from '@otrack-lib/core/helper-services/print.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  host: {
    '[class.isPrinting]': 'printService.isPrinting'
  }
})
export class AuthComponent implements OnDestroy {
  loading: boolean;
  constructor(private router: Router, public printService: PrintService) {
    // this.loading = true;
    // router.events.subscribe((event : RouterEvent) => {
    //   this.navigationInterceptor(event);
    // });
  }

  // navigationInterceptor(event : RouterEvent): void {
  //   if (event instanceof NavigationStart) {
  //     this.loading = true;
  //   }
  //   if (event instanceof NavigationEnd) {
  //     this.loading = false;
  //   }

  //   // Set loading state to false in both of the below events to hide the spinner in case a request fails
  //   if (event instanceof NavigationCancel) {
  //     this.loading = false;
  //   }
  //   if (event instanceof NavigationError) {
  //     this.loading = false;
  //   }
  // }

  ngOnDestroy(): void {
    // this.alive = false;
  }
}
