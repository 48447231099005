import { OrderEffects } from './state/order-state/order.effects';
import { CustomerEffects } from './state/customer-state/customer.effects';
import { VendorEffects } from './state/vendor-state/vendor.effects';
import { PrintModule } from '@otrack-lib/common/components/print/print.module';
import { CoreModule } from '@otrack-lib/core/core.module';
import { GlobalErrorHandler } from '@otrack-lib/core/global-error-handler';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './common/layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { FormBuilder } from '@angular/forms';
import { PrintLayoutComponent } from '@otrack-lib/common/components/print-layout/print-layout.component';
import { PrintComponent } from '@otrack-lib/common/components/print/print.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ClientTemplateModule } from './modules/template/template.module';
import { UtilsService } from '@otrack-lib/core/helper-services/utils.service';

/* NgRx */
import { StoreModule, MetaReducer, META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { storageMetaReducer } from './storage.metareducer';
import { ROOT_LOCAL_STORAGE_KEY, ROOT_STORAGE_KEYS } from './app.token';
import { reducers } from './state';
import { IConfiguration } from '@otrack-lib/models/configuration.model';
import { CookieService } from 'ngx-cookie-service';
import { PurchaseOrderEffects } from './state/purchase-order-state/purchase-order.effects';
import { LocalStorageService } from '@otrack-lib/core/helper-services/local-storage.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '@otrack-lib/core/interceptors/token.interceptor.service';

const configuration: IConfiguration = {
  env: environment.env,
  apiURL: environment.apiURL,
  mode: environment.mode,
  firebase: environment.firebase,
  production: environment.production,
  appDomain: environment.appDomain,
  signupFunction: environment.signupFunction,
  checkstatusFunction: environment.checkstatusFunction,
  signoutFunction: environment.signoutFunction,
  loginPageUrl: environment.loginPageUrl,
  sentryDsn: environment.sentryDsn,
  localToken: environment.localToken,
};

export function getMetaReducers(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService
): MetaReducer<any> {
  return storageMetaReducer(saveKeys, localStorageKey, storageService);
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    PrintLayoutComponent,
    PrintComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(configuration), // Inject configuration in Library coreModule
    SharedModule,
    ClientTemplateModule,
    PrintModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      name: 'Otrack Dev Envirnoment',
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([CustomerEffects, VendorEffects, OrderEffects, PurchaseOrderEffects])
  ],
  schemas: [],
  providers: [
    FormBuilder,
    CookieService,
    LocalStorageService,
    UtilsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ROOT_STORAGE_KEYS,
      useValue: ['customer', 'user', 'order'],
      multi: true
    },
    { provide: ROOT_LOCAL_STORAGE_KEY, useValue: '__app_user__', multi: true },
    {
      provide: META_REDUCERS,
      deps: [ROOT_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getMetaReducers,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
