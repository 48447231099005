import { Injectable } from '@angular/core';
import * as CustomerSelectors from '@client-app/state/customer-state/customer.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CustomerService } from '@otrack-lib/core/services/customer.service';
import { InvoiceService } from '@otrack-lib/core/services/invoice.service';
import { SalesOrderService } from '@otrack-lib/core/services/sales-order.service';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OrderHandlingService } from './../../services/order-handling.service';
import * as OrderActions from './order.actions';
import { OrderState } from './order.reducer';
import * as OrderSelectors from './order.selectors';



@Injectable()
export class OrderEffects {

  constructor(private actions$: Actions,
    private orderHandlingService: OrderHandlingService,
    private customerService: CustomerService,
    private store: Store<OrderState>,
    private saleOrderService: SalesOrderService,
    private invoiceService: InvoiceService) { }


  loadPreferedList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadPreferredList),
      withLatestFrom(this.store.pipe(select(CustomerSelectors.getCurrentCustomerId))),
      map(([action, customerId]) => customerId),
      withLatestFrom(this.store.pipe(select(OrderSelectors.getCustomerPreferredList))),
      map(([customer, list]) => ({ customerId: customer, list })),
      switchMap(storeValue => {
        if (storeValue.list && storeValue.list.length > 0) { // means preferred list is already in the store
          return of(OrderActions.loadPreferredListSuccess({ data: storeValue.list }));
        } else {
          return this.invoiceService.getCustomerPreferedList(storeValue.customerId).pipe(
            map(data => OrderActions.loadPreferredListSuccess({ data })),
            catchError(error => of(OrderActions.loadPreferredListFailure({ error }))));
        }
      }
      )
    );
  });

  updatePreferredOrderList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadPreferredListSuccess),
      withLatestFrom(this.store.pipe(select(OrderSelectors.getCurrentOrderList))),
      map(([action, currentOrderList]) =>
        this.orderHandlingService.updatePreferredOrderUsingCurrentOrderList(action.data, currentOrderList))
    );
  }, { dispatch: false });


  updateOrderSuccessList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.updateOrderSuccess),
      withLatestFrom(this.store.pipe(select(OrderSelectors.getCustomerPreferredList))),
      switchMap(([action, currentPreferredOrderList]) => {
        // tslint:disable-next-line:max-line-length
        // this.orderHandlingService.updateOrderInternalId(action.data);
        const updatedPreferredList = this.orderHandlingService.updatePreferredOrderUsingCurrentOrderList(currentPreferredOrderList, action.data);
        return of(OrderActions.loadPreferredListSuccess({ data: updatedPreferredList }));
      })
    );
  });


  updateCurrentOrderByProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.updateOrderByProduct),
      withLatestFrom(this.store.pipe(select(OrderSelectors.getCurrentOrderList))),
      map(([action, orderList]) => ({ existingOrderList: orderList, updatedProduct: action.data.product })),
      switchMap(updatedOrderObj =>
        of(this.orderHandlingService.updateOrderListByProduct(updatedOrderObj.existingOrderList, updatedOrderObj.updatedProduct))
          .pipe(
            map(data => OrderActions.updateOrderSuccess({ data })),
          )
      )
    );
  });

  updateCurrentOrderByOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.updateOrderByOrder),
      withLatestFrom(this.store.pipe(select(OrderSelectors.getCurrentOrderList))),
      map(([action, orderList]) => ({ existingOrderList: orderList, updatedOrder: action.data.order })),
      switchMap(updatedOrderObj =>
        of(this.orderHandlingService.updateOrderListByOrder(updatedOrderObj.existingOrderList, updatedOrderObj.updatedOrder))
          .pipe(
            map(data => OrderActions.updateOrderSuccess({ data })),
          )
      )
    );
  });

  postSaleOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.postSaleOrder),
      withLatestFrom(this.store.pipe(select(OrderSelectors.getOrderInfo))),
      map(([action, orderInfo]) => ({ order: orderInfo, editOrderId: action.data })),
      switchMap(postOrderInfo => {
        const transactionRequestObj: IInvoice = {
          customerId: postOrderInfo.order.customerId,
          memo: postOrderInfo.order.memo,
          orderId: postOrderInfo.editOrderId,
          IsTaxable: postOrderInfo.order.isTaxApply,
          isTaxable: postOrderInfo.order.isTaxApply,
          items: postOrderInfo.order.currentOrderList,
          discountType: postOrderInfo.order.discountType,
          discountValue: postOrderInfo.order.discountValue
        };
        if (postOrderInfo.editOrderId > 0) { // means edit purchase order
          transactionRequestObj.orderId = postOrderInfo.editOrderId;
          transactionRequestObj.salesOrderId = postOrderInfo.editOrderId;
          return this.invoiceService.updateSaleOrderOnServer(transactionRequestObj)
            .pipe(
              map(data => OrderActions.postOrderSuccess({ data })),
              catchError(error => of(OrderActions.postOrderFailure({ error })))
            );
        } else {
          return this.invoiceService.postSaleOrderOnServer(transactionRequestObj)
            .pipe(
              map(data => OrderActions.postOrderSuccess({ data })),
              catchError(error => of(OrderActions.postOrderFailure({ error })))
            );
        }
      }
      )
    );
  });


  postSaleOrderToInvoice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.updateSaleOrderToInvoice),
      withLatestFrom(this.store.pipe(select(OrderSelectors.getOrderInfo))),
      map(([action, orderInfo]) => ({ order: orderInfo, editOrderId: action.data })),
      switchMap(postOrderInfo => {
        const transactionRequestObj: IInvoice = {
          customerId: postOrderInfo.order.customerId,
          memo: postOrderInfo.order.memo,
          orderId: postOrderInfo.editOrderId,
          IsTaxable: postOrderInfo.order.isTaxApply,
          isTaxable: postOrderInfo.order.isTaxApply,
          items: postOrderInfo.order.currentOrderList,
          discountType: postOrderInfo.order.discountType,
          discountValue: postOrderInfo.order.discountValue
        };

        if (postOrderInfo.editOrderId > 0) { // means edit purchase order
          transactionRequestObj.salesOrderId = postOrderInfo.editOrderId;
          transactionRequestObj.orderId = postOrderInfo.editOrderId;
          return this.invoiceService.updateSaleOrderOnServer(transactionRequestObj)
            .pipe(
              concatMap(savedSaleOrder => this.saleOrderService.convertToInvoice(savedSaleOrder.orderId)),
              map(data => OrderActions.postOrderSuccess({ data })),
              catchError(error => of(OrderActions.postOrderFailure({ error })))
            );
        }
      }
      )
    );
  });

  postInvoice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.postInvoice),
      withLatestFrom(this.store.pipe(select(OrderSelectors.getOrderInfo))),
      map(([action, orderInfo]) => ({ order: orderInfo, invoiceInfo: action.data })),
      switchMap(postOrderInfo => {
        const transactionRequestObj: IInvoice = {
          customerId: postOrderInfo.order.customerId,
          memo: postOrderInfo.order.memo,
          IsTaxable: postOrderInfo.order.isTaxApply,
          isTaxable: postOrderInfo.order.isTaxApply,
          items: postOrderInfo.order.currentOrderList,
          discountType: postOrderInfo.order.discountType,
          discountValue: postOrderInfo.order.discountValue
        };
        if (postOrderInfo.invoiceInfo.orderId > 0) { // means edit purchase order
          transactionRequestObj.orderId = postOrderInfo.invoiceInfo.orderId;
          // tslint:disable-next-line:max-line-length
          return this.invoiceService.postOrder(transactionRequestObj, postOrderInfo.invoiceInfo.orderType, true)
            .pipe(
              map(data => OrderActions.postOrderSuccess({ data })),
              catchError(error => of(OrderActions.postOrderFailure({ error })))
            );
        } else {
          return this.invoiceService.postOrder(transactionRequestObj, postOrderInfo.invoiceInfo.orderType, false)
            .pipe(
              map(data => OrderActions.postOrderSuccess({ data })),
              catchError(error => of(OrderActions.postOrderFailure({ error })))
            );
        }
      }
      )
    );
  });

  loadSaleOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadSaleOrder),
      switchMap(action =>
        this.invoiceService.getSalesOrder(action.data)
          .pipe(
            map(data => {
              this.orderHandlingService.updateOrderInternalId(data.items);
              return OrderActions.loadSaleOrderSuccess({ data });
            }),
            catchError(error => of(OrderActions.loadSaleOrderFailure({ error })))
          )
      )
    );
  });


  loadInvoice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderActions.loadInvoice),
      switchMap(action =>
        this.invoiceService.getOrder(action.data)
          .pipe(
            map(data => {
              this.orderHandlingService.updateOrderInternalId(data.items);
              return OrderActions.loadSaleOrderSuccess({ data });
            }),
            catchError(error => of(OrderActions.loadSaleOrderFailure({ error })))
          )
      )
    );
  });

}




