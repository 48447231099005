import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserActions, UserActionTypes } from './user.actions';


export interface State {
  userName: string;
}

const initialState: State = {
  userName: null,
};

// Selector functions
const getUserFeatureState = createFeatureSelector<State>('user');

export const isLoggedIn = createSelector(
  getUserFeatureState,
  state => !!state.userName
);

export const getCurrentUserName = createSelector(
  getUserFeatureState,
  state => state.userName ? state.userName : '',
);

export function reducer(state: State = initialState, action: UserActions): State {
  switch (action.type) {
    case UserActionTypes.SetUserName:
      // consol.log(state);
      // consol.log(action.payload);
      return {
        ...state,
        userName: action.payload
      };
    case UserActionTypes.ResetUserName:
      // consol.log('logout');
      return {
        ...state,
        userName: null
      };
    default:
      return state;
  }
}
