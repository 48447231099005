

import { createAction, props } from '@ngrx/store';
import { ICategoryProduct, IQuickProductDetail } from '@otrack-lib/models/product/quick-order.models';
import { IOrder } from '@otrack-lib/models/order/order.model';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';
import { OrderTypes } from '@otrack-lib/enums';
import { DiscountType } from '@otrack-lib/enums/discount-type.enum';


export const loadSaleOrder = createAction(
  '[SaleOrder-Edit] Load Sale Order',
  props<{ data: number }>()
);

export const loadInvoice = createAction(
  '[Invoice-Edit] Load Invoice',
  props<{ data: number }>()
);

export const loadSaleOrderSuccess = createAction(
  '[SaleOrder-Edit] Load Sale Order Success',
  props<{ data: IInvoice }>()
);

export const loadSaleOrderFailure = createAction(
  '[SaleOrder-Edit] Load Sale Order Failure',
  props<{ error: any }>()
);

export const loadPreferredList = createAction(
  '[Order] Load Prefered Customer Products'
);

export const loadPreferredListSuccess = createAction(
  '[Order] Load Prefered Customer Products Success',
  props<{ data: ICategoryProduct[] }>()
);

export const loadPreferredListFailure = createAction(
  '[Order] Load Prefered Customer Products Failure',
  props<{ error: any }>()
);

export const updateOrderByProduct = createAction(
  '[Order] Set Current Order by Product',
  props<{ data: { product: IQuickProductDetail } }>()
);

export const updateOrderByOrder = createAction(
  '[Order] Set Current Order by Order',
  props<{ data: { order: IOrder } }>()
);

export const setCurrentOrderType = createAction(
  '[Order] Set Order Type',
  props<{ data: { orderType: OrderTypes } }>()
);

export const updateOrderSuccess = createAction(
  '[Order] Set Current Order Success',
  props<{ data: IOrder[] }>()
);

export const setTaxApplyOnOrder = createAction(
  '[Order] Set Tax Apply',
  props<{ data: boolean }>()
);

export const applyDiscount = createAction(
  '[Order] Apply Discount',
  props<{ data: { discountType: DiscountType, discountValue: number } }>()
);

export const resetOrderOnly = createAction(
  '[Order] Reset order related information after submit'
);

export const resetAll = createAction(
  '[Order]  Reset all'
);

export const setMemo = createAction(
  '[Order] Set Memo',
  props<{ data: string }>()
);

export const postSaleOrder = createAction(
  '[Order] Submit Sale Order to Server',
  props<{ data: number }>()
);

export const updateSaleOrderToInvoice = createAction(
  '[Order] update SaleOrder to Invoice Server',
  props<{ data: number }>()
);

export const postInvoice = createAction(
  '[Order] Submit Invoice to Server',
  props<{ data: { orderId: number, orderType: OrderTypes } }>()
);

export const postOrderSuccess = createAction(
  '[Order]  Submit SaleOrder to Server- Success',
  props<{ data: IInvoice }>()
);

export const postOrderFailure = createAction(
  '[Order]  Submit SaleOrder to Server- Failure',
  props<{ error: any }>()
);


/* NgRx */
// import { Action } from '@ngrx/store';
// import { ICustomerDetail, ICustomer } from '@otrack-lib/models/customer/customer.model';
// import { IOrder } from '@otrack-lib/models/order/order.model';

// export enum OrderActionTypes {
//   SetCurrentOrderList = '[Order] Set Order List',
//   AddNewItemOrder = '[Order] Add New Item Order',
//   UpsetCurrentOrder = '[Order] Upset Current Order',
//   DeleteOrder = '[Order] Delete Order',
//   ClearZeroQtyOrder = '[Order] Clear zero qty Orders',
//   ClearCurrentOrder = '[Order] Clear Current Order',
//   SetTaxId = '[Order] Set TaxId',
//   SetIsTaxApply = '[Order] Set IsTax Apply Flag',
//   SetOrdreMemo = '[Order] Set Order memo',
//   ResetOrderOnly = '[Order]  Reset order related information after submit'
// }

// export class SetCurrentOrderList implements Action {
//   readonly type = OrderActionTypes.SetCurrentOrderList;
//   constructor(public payload: IOrder[]) { }
// }

// export class AddNewItemOrder implements Action {
//   readonly type = OrderActionTypes.AddNewItemOrder;
//   constructor(public payload: IOrder) { }
// }

// export class UpsetCurrentOrder implements Action {
//   readonly type = OrderActionTypes.UpsetCurrentOrder;
//   constructor(public payload: IOrder, public fromQuickOrder: boolean = false) { }
// }

// export class SetTaxId implements Action {
//   readonly type = OrderActionTypes.SetTaxId;
//   constructor(public payload: number) { }
// }
// export class SetOrdreMemo implements Action {
//   readonly type = OrderActionTypes.SetOrdreMemo;
//   constructor(public payload: string) { }
// }
// export class DeleteOrder implements Action {
//   readonly type = OrderActionTypes.DeleteOrder;
//   constructor(public payload: IOrder) { }
// }

// export class SetIsTaxApply implements Action {
//   readonly type = OrderActionTypes.SetIsTaxApply;
//   constructor(public payload: boolean) { }
// }

// export class ClearZeroQtyOrder implements Action {
//   readonly type = OrderActionTypes.ClearZeroQtyOrder;
//   constructor() { }
// }


// export class ResetOrderOnly implements Action {
//   readonly type = OrderActionTypes.ResetOrderOnly;
//   constructor() { }
// }


// export class ClearCurrentOrder implements Action {
//   readonly type = OrderActionTypes.ClearCurrentOrder;
//   constructor() { }
// }

// export type OrderActions = SetCurrentOrderList | UpsetCurrentOrder | ClearCurrentOrder
//   | DeleteOrder | ClearZeroQtyOrder | AddNewItemOrder | SetIsTaxApply | SetTaxId | SetOrdreMemo | ResetOrderOnly;
