import { VendorService } from '@otrack-lib/core/services/vendor.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { VendorActions } from './';


@Injectable()
export class VendorEffects {

  constructor( private actions$: Actions,
               private vendorService: VendorService) {}


  loadCurrentVendor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VendorActions.loadCurrentVendor),
      switchMap( action =>
        this.vendorService.getVendorDetail(action.data).pipe(
          map(data =>  VendorActions.loadCurrentVendorSuccess({data}) ),
           catchError(error => of(VendorActions.loadCurrentVendorFailure({ error })))
        )
      )
    );
  });


}




