<div id="http-loader" *ngIf="isSpinnerVisible">
        <!-- <div class="centered">
                <div class="inner">
             <div><img class="icon-loading2" src="assets/images/onlylogo.png"  /></div> 
                <div class="spinner">
                    <div class="rect1"></div>
                    <div style="background-color: #01abcc;"  class="rect2"></div>
                    <div class="rect3"></div>
                    <div style="background-color: #01abcc;" class="rect4"></div>
                    <div class="rect5"></div>
                  </div>
                </div>
              </div>  -->
              <div class="centered">
                  <div class="inner">
                    <div><img class="icon-loading" width="60px"  src="assets/images/onlylogo.png" /></div>
                  <div class="spinner">
                      <div class="rect1"></div>
                      <div style="background-color: #01abcc;" class="rect2"></div>
                      <div class="rect3"></div>
                      <div style="background-color: #01abcc;" class="rect4"></div>
                      <div class="rect5"></div>
                    </div>
                  </div>
                </div> 
    <!-- <div class="loader-bg">

        <div class="sk-line-material" [class.colored]="!backgroundColor" *ngIf="spinner === Spinkit.skLine">
            <div class="sk-child sk-bounce1" [style.background-color]='backgroundColor'></div>
        </div>
    </div> -->
</div>
