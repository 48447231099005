<div *ngIf="!minibox" style="display:flex;flex-direction:column;align-items:center">
  <div style="margin-bottom:0px;width:100%">
    <div class="form-group">
      <label style="margin-left:45px; margin-bottom:0;" for="exampleInputEmail1">Quantity</label>
      <input type="number" (click)="clearValueIfZero()" autocomplete="off" required placeholder="QTY" class="form-control input-box"
      (keyup)="updateChanges()"
         [(ngModel)]="value"  #goBack='ngModel' name="qty" />
    </div>
  </div>
  <div style="margin-top:0px">
      <button class="plusminusbutton" type="submit" matSuffix aria-label="Clear" (click)="incrementValue()">
        +
      </button>
      <button class="plusminusbutton" type="submit" matSuffix aria-label="Clear" (click)="decrementValue()">
        -
      </button>
    <button type="submit" [disabled]="value == 0" matSuffix class="clearbutton" aria-label="Clear" (click)="clearValue()">
      CLEAR
    </button>
  </div>
</div>

    <div *ngIf="minibox" style="display:flex;flex-direction:row;align-items:center">
      <button type="submit" matSuffix aria-label="Clear" (click)="incrementValue()">
        +
      </button>
      <input type="number" (click)="clearValueIfZero()"   (keyup)="onKey($event)" autocomplete="off" required placeholder="QTY" class="form-control table-text-box"
        style="text-align: center; padding: 0px" [(ngModel)]="value" #salesprice='ngModel' name="salesPrice" />
      <button type="submit" matSuffix aria-label="Clear" (click)="decrementValue()">
        -
      </button>
    </div>



    <!-- <mat-form-field style= "width:100px;margin-top:100px;" class="example-form-field">

          <input  (click)="clearValueIfZero()"  class="class-3-input" type="number"   matInput                       placeholder="Size" [(ngModel)]="size" (ngModelChange)="updateChanges()">
        </mat-form-field> -->
