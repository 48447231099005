import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as CustomerStore from './customer.reducer';

export const selectState = createFeatureSelector<CustomerStore.CustomerState>(
  CustomerStore.currentCustomerKey
);


export const getCurrentCustomerId = createSelector(
  selectState,
  (state: CustomerStore.CustomerState) => {
    return state.currentCustomer ? state.currentCustomer.customerId : undefined;
  }
);

export const getCurrentCustomer = createSelector(
  selectState,
  (state: CustomerStore.CustomerState) => {
    return state.currentCustomer ? state.currentCustomer : undefined;
  }
);

export const isCustomerSelected = createSelector(
  selectState,
  (state: CustomerStore.CustomerState) => {
    return state && state.currentCustomer  ? true : false;
  }
);

export const getLoadingStatus = createSelector(
  selectState,
  (state: CustomerStore.CustomerState) => state ? state.loading : false
);


export const getCustomerSuccessStatus = createSelector(
  selectState,
  (state: CustomerStore.CustomerState) => state ? state.success : false
);

export const getCustomerTaxPercent = createSelector(
  selectState,
  state => state.currentCustomer ?   state.currentCustomer.taxPercent : 0,
);
