import { CustomerService } from '@otrack-lib/core/services/customer.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CustomerActions } from './';


@Injectable()
export class CustomerEffects {

  constructor( private actions$: Actions,
               private customerService: CustomerService) {}


  loadCurrentCustomer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.loadCurrentCustomer),
      switchMap( action =>
        this.customerService.getCustomerListById(action.data).pipe(
          map(data =>  CustomerActions.loadCurrentCustomerSuccess({data}) ),
           catchError(error => of(CustomerActions.loadCurrentCustomerFailure({ error })))
        )
      )
    );
  });


}




