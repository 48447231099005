import { ICategoryProduct } from '@otrack-lib/models/product/quick-order.models';
import { IQuickProductDetail } from '@otrack-lib/models/product/quick-order.models';
import { Injectable } from '@angular/core';
import { IOrder } from '@otrack-lib/models/order/order.model';


@Injectable({
  providedIn: 'root'
})
export class OrderHandlingService {

  constructor() {
  }


  /**
   * This method will update orderList tempId
   * @param orderList
   */
  updateOrderInternalId( orderList: IOrder[]) {
      const unique = orderList.map(d => d.productId).filter((item, i, ar) => ar.indexOf(item) === i);
      console.log(unique);
      unique.forEach(u => {
          let id = 1;
              orderList.filter(c => c.productId === u).forEach(d => {
              d.tempId = id;
              id++;
              }
          );
      });
  }


  // /**
  //  * This method take the orderList and update the order list by inProduct params
  //  * @param orderList Current OrderList
  //  * @param inProduct new or Updated Product
  //  */
  // old___updateOrderListByProduct(orderList: IOrder[], inProduct: IQuickProductDetail): IOrder[] {
  //   const output  = Object.assign([], orderList) as IOrder[];
  //   if (!output) {
  //     return output;
  //   }
  //   const existingOrdertIndex = output.findIndex(c => c.productId === inProduct.productId);

  //   if (existingOrdertIndex >= 0 ) { // found
  //      if (inProduct.quantity === 0 ) { // delete the product if quantity is zero
  //       this. deleteOrderFromListByProductId(output, inProduct.productId);
  //      } else {
  //       output[existingOrdertIndex] = this.updateProductToIOrder( output[existingOrdertIndex] , inProduct);
  //      }
  //   } else {
  //     if (inProduct.quantity !== 0 ) { // not add if quantity is zero
  //       output.push(this.mapProductDetailToIOrder(inProduct));
  //     }
  //   }
  //   return output;
  // }




  /**
   * This method take the orderList and update the order list by inProduct params
   * @param orderList Current OrderList
   * @param inProduct new or Updated Product
   * NEW NEW
   */
  updateOrderListByProduct(orderList: IOrder[], inProduct: IQuickProductDetail): IOrder[] {
    const output  = Object.assign([], orderList) as IOrder[];
    if (!output) {
      return output;
    }
    const existingOrdertIndex = output.findIndex(c => c.productId === inProduct.productId && c.tempId === inProduct.tempId);

    if (existingOrdertIndex >= 0 ) { // found
       if (inProduct.quantity === 0 ) { // delete the product if quantity is zero
        this. deleteOrderFromListByProductId(output, inProduct);
       } else {
        output[existingOrdertIndex] = this.updateProductToIOrder( output[existingOrdertIndex] , inProduct);
       }
    } else {
      if (inProduct.quantity !== 0 ) { // not add if quantity is zero
        output.push(this.mapProductDetailToIOrder(inProduct));
      }
    }
    return output;
  }

 private deleteOrderFromListByProductId(orders: IOrder[], product: IQuickProductDetail) {
    const orderIndex = orders.findIndex(c => c.productId === product.productId && c.tempId === product.tempId);
    if (orderIndex > -1) {
      orders.splice(orderIndex, 1);
    }
 }



// /**
//  * This method take the orderList and update the order list by inOrder params
//  * @param orderList Current OrderList
//  * @param inOrder new or updated order
//  */
//   old____updateOrderListByOrder(orderList: IOrder[], inOrder: IOrder): IOrder[] {
//     const output  = Object.assign([], orderList) as IOrder[];
//     if (!output) {
//       return output;
//     }
//     const existingOrdertIndex = output.findIndex(c => c.productId === inOrder.productId);

//     if (existingOrdertIndex >= 0 ) { // found
//       if (inOrder.quantity === 0 ) { // delete the product if quantity is zero
//         this. deleteOrderFromListByProductId(output, inOrder.productId);
//        } else {
//         output[existingOrdertIndex] = inOrder;
//        }

//     } else {
//       if (inOrder.quantity !== 0 ) { // not add if quantity is zero
//         output.push(inOrder);
//       }
//     }
//     return output;
//   }


  /**
 * This method take the orderList and update the order list by inOrder params
 * @param orderList Current OrderList
 * @param inOrder new or updated order
 * NEW
 */
updateOrderListByOrder(orderList: IOrder[], inOrder: IOrder): IOrder[] {
  const output  = Object.assign([], orderList) as IOrder[];
  if (!output) {
    return output;
  }

  const existingOrdertIndex = output.findIndex(c => c.productId === inOrder.productId && c.tempId === inOrder.tempId);

  if (existingOrdertIndex >= 0 ) { // found
    if (inOrder.quantity === 0 ) { // delete the product if quantity is zero
      this. deleteOrderFromListByOrder(output, inOrder);
     } else {
      output[existingOrdertIndex] = inOrder;
     }

  } else {
    if (inOrder.quantity !== 0 ) { // not add if quantity is zero
      let tempId = this.getNextInternalId(orderList, inOrder.productId);
      inOrder.tempId = tempId;
      output.push(inOrder);
    }
  }
  return output;
}


private getNextInternalId( orderList: IOrder[], inProductId: number) {
  const orderFilterList = orderList.filter(o => o.productId === inProductId );
  if (orderFilterList && orderFilterList.length > 0) {
      let aa = Math.max.apply(Math, orderFilterList.map(function(o) { return o.tempId; }));
      return aa ? ++aa : 1;
  } else {
      return 1;
  }
}


// /**
//  * This method is use to update preferredOrder list using orderList for udpate qty and prices
//  * @param categoryProducts
//  * @param currentOrderList
//  */
//   old____updatePreferredOrderUsingCurrentOrderList(categoryProducts: ICategoryProduct[], currentOrderList: IOrder[]): ICategoryProduct[] {
//     if (currentOrderList && categoryProducts ) {
//         currentOrderList.forEach( order =>  this.updatePreferredOrderByOrder(categoryProducts, order));
//     }
//     return categoryProducts;
//   }


  /**
   * This method is use to update preferredOrder list using orderList for udpate qty and prices
   * @param categoryProducts
   * @param currentOrderList
   * NEW NEW NEW
   */
  updatePreferredOrderUsingCurrentOrderList(categoryProducts: ICategoryProduct[], currentOrderList: IOrder[]): ICategoryProduct[] {
    this.resetQtyInPreferredlist(categoryProducts);

    if (currentOrderList && categoryProducts ) {
        currentOrderList.forEach( order =>  this.updatePreferredOrderByOrder(categoryProducts, order));
    }
    return categoryProducts;
  }


  private resetQtyInPreferredlist(categoryProducts: ICategoryProduct[]) {
    categoryProducts.forEach(cp => {
      cp.products.forEach(p => {
        p.qty = 0;
        p.quantity = 0;
      } );
    });
  }

  private updatePreferredOrderByOrder(categoryProducts: ICategoryProduct[], order: IOrder) {
    let isOrderFound = false;
    for (const categoryProduct of categoryProducts) {
        const existingProductIndex = categoryProduct.products.findIndex(prod => prod.productId === order.productId && prod.tempId === order.tempId);
        if ( existingProductIndex >= 0 ) {
          categoryProduct.products[existingProductIndex] =
            this.updateOrderToIQuickProductDetail( categoryProduct.products[existingProductIndex] , order);
          isOrderFound = true;
        }
    }

    if (!isOrderFound) {
        this.addProductInPrefferedOrderListUsingOrder(categoryProducts, order);
    }

    return categoryProducts;
  }


  isValidPurchaseOrder(currentOrderList: IOrder[]): boolean {
    if (currentOrderList && currentOrderList.length <= 0) {
      return false;
    }
    return currentOrderList.reduce((totalAmount, current) => totalAmount + (current.quantity * current.cost), 0) >= 0;
    // check total should be greater than or equal to 0
  }

  isValidInvoiceOrder(currentOrderList: IOrder[]): boolean {
    if (currentOrderList && currentOrderList.length <= 0) {
      return false;
    }
    return currentOrderList.reduce((totalAmount, current) => totalAmount + (current.quantity * current.price), 0) >= 0;
    // check total should be greater than or equal to 0
  }

  // private deleteOrderFromListByProductId(orders: IOrder[], productId: number) {
  //     const orderIndex = orders.findIndex(c => c.productId === productId);
  //     if (orderIndex > -1) {
  //       orders.splice(orderIndex, 1);
  //     }
  // }

  private deleteOrderFromListByOrder(orders: IOrder[], order: IOrder) {
    const orderIndex = orders.findIndex(c => c.productId === order.productId && c.tempId === order.tempId);
    if (orderIndex > -1) {
      orders.splice(orderIndex, 1);
    }
}

  // private updatePreferredOrderByOrder(categoryProducts: ICategoryProduct[], order: IOrder) {
  //   let isOrderFound = false;
  //   for (const categoryProduct of categoryProducts) {
  //       const existingProductIndex = categoryProduct.products.findIndex(prod => prod.productId === order.productId);
  //       if ( existingProductIndex >= 0 ) {
  //         categoryProduct.products[existingProductIndex] =
  //           this.updateOrderToIQuickProductDetail( categoryProduct.products[existingProductIndex] , order);
  //         isOrderFound = true;
  //       }
  //   }

  //   if (!isOrderFound) {
  //       this.addProductInPrefferedOrderListUsingOrder(categoryProducts, order);
  //   }

  //   return categoryProducts;
  // }


  private addProductInPrefferedOrderListUsingOrder(categoryProducts: ICategoryProduct[], order: IOrder) {

    const existingCategoryIndex = categoryProducts.findIndex(category => category.categoryName === order.rootCategoryName);
    if ( existingCategoryIndex >= 0 ) { // incase category already exist
      categoryProducts[existingCategoryIndex].products.push(this.mapIOrderToIQuickProductDetail(order));
    } else {
      categoryProducts.push({categoryName: order.rootCategoryName, products: [this.mapIOrderToIQuickProductDetail(order)]});
    }
  }

  private updateProductToIOrder(order: IOrder, inProduct: IQuickProductDetail): IOrder {
    return {...order,
              quantity : inProduct.quantity,
              cost : inProduct.customCost ? inProduct.customCost : inProduct.actualCost,
              price : inProduct.price
          };
  }

  private updateOrderToIQuickProductDetail(inProduct: IQuickProductDetail, inOrder: IOrder):  IQuickProductDetail {
    return {...inProduct,
              quantity : inOrder.quantity,
              customCost : inOrder.cost ? inOrder.cost : inOrder.actualCost,
              price : inOrder.price,
              isTaxable: inOrder.isTaxable,
          };
  }

  private mapIOrderToIQuickProductDetail(order: IOrder): IQuickProductDetail {
    return {
              categoryName: order.categoryName,
              productId: order.productId,
              name: order.productName,
              description: order.description,
              quantity: order.quantity,
              basePrice: order.price,
              price: order.price,
              purchaseCost: order.cost,
              actualPrice: order.actualPrice,
              isTaxable: order.isTaxable,
              tempId: order.tempId,
              rootCategoryName: order.rootCategoryName
            };
  }

  private mapProductDetailToIOrder(prod: IQuickProductDetail): IOrder {
    return  {
            productId: prod.productId,
            actualPrice: prod.actualPrice,
            quantity: prod.quantity,
            price: prod.price ? prod.price : prod.basePrice,
            cost: prod.customCost ? prod.customCost : prod.actualCost,
            isTaxable: prod.isTaxable,
            categoryName: prod.categoryName,
            rootCategoryName: prod.categoryName,
            description: prod.description,
            tempId: prod.tempId,
            productName: prod.name
            };
  }


}
