<ng-container *ngIf="invoice">
  <div class="flex-container invoice print-invoice" id="print-section">
      <div>
        <table style="margin-bottom: 10px;">
          <colgroup>
            <col style="width: 40%" />
            <col style="width: 20%" />
            <col style="width: 30%" />
          </colgroup>
          <tr class="company-header-detail">
            <td colspan="2">
              <!-- show company name only for the invoice is synced -->
              <div *ngIf="isOTOCustomer">
                <h2 class="company-heading">
                  {{ invoice?.company?.name }}
                </h2>
                {{ invoice?.company?.address }}<br>
                {{ invoice?.company?.city }} {{ invoice?.company?.state }} {{ invoice?.company?.postalCode }}<br>
                {{ invoice?.company?.country }}
              </div>
            </td>
            <td>
              <div class="headerinvoicediv" >
                <ng-container *ngIf="invoice?.orderType === orderType.Invoice">
                  <h2 class="company-heading"> INV # {{ invoice?.invoiceNumber }}</h2>
                </ng-container>
                <ng-container *ngIf="invoice?.orderType === orderType.CreditMemo">
                  <h2 class="company-heading"> CM # {{ invoice?.invoiceNumber }} </h2>
                </ng-container>
                <ng-container *ngIf="invoice?.invoiceNumber == null">
                  <h2 class="company-heading"> SO # {{ invoice?.orderId }}</h2>
                </ng-container>
                <ng-container *ngIf="invoice?.orderType === orderType.PurchaseBill">
                  <h2 class="company-heading"> BILL # {{ invoice?.invoiceNumber }}</h2>
                </ng-container>
                <ng-container *ngIf="invoice?.orderType === orderType.PurchaseOrder">
                  <h2 class="company-heading"> PO # {{ invoice?.invoiceNumber }}</h2>
                </ng-container>
                <h3 class="header-subheading">{{ invoice?.invoiceDate | date:'dd MMM, yyyy' }}</h3>
              </div>
            </td>
          </tr>
          <tr *ngIf="invoice?.invoiceNumber == null">
            <td colspan="3">
              <p class="notinvoicemsg">This is not the actual invoice</p>
            </td>
          </tr>
          <tr *ngIf="invoice?.orderType === orderType.PurchaseOrder">
            <td colspan="3">
              <p class="notinvoicemsg">This is not the actual bill</p>
            </td>
          </tr>
          <tr>
            <td style="padding-top: 15px; vertical-align: top;">
              <div>
                <div class="bold-text">BILL TO</div>
                <div class="normal-text">{{ invoice?.customer.companyName != null ? invoice?.customer.companyName : invoice?.customerName }}</div>
                <div class="normal-text">{{ this.invoice?.customer?.address1 }}</div>
                <div class="normal-text">{{ this.invoice?.customer?.city }} {{ this.invoice?.customer?.state }}</div>
                <div class="normal-text">{{ this.invoice?.customer?.postalCode }} {{ this.invoice?.customer?.country }}</div>
              </div>
            </td>
            <td>
              <div *ngIf="invoice?.isPaid && invoice?.orderType === orderType.Invoice">
                <h5 class="paid">
                  [PAID]
                </h5>
              </div>
            </td>
            <td style="padding-top: 15px; vertical-align: top;">
              <div *ngIf="invoice?.invoiceNumber != null && isOTOCustomer">
                <div class="bold-text" *ngIf="invoice?.orderType === orderType.Invoice" style="text-align: right;">INOVICE BY</div>
                <div class="bold-text" *ngIf="invoice?.orderType === orderType.CreditMemo" style="text-align: right;">CREDITMEMO BY</div>
                <div class="bold-text" *ngIf="invoice?.orderType === orderType.PurchaseBill" style="text-align: right;">BILLED BY</div>
                <div class="bold-text" *ngIf="invoice?.invoiceNumber == null" style="text-align: right;">ORDER BY</div>
                <div class="normal-text" style="text-align: right;">{{ invoice?.invoiceBy | uppercase }}</div>
              </div>
              <div>
                <div class="bold-text" style="text-align: right;">TOTAL AMOUNT</div>
                <div class="normal-text" style="text-align: right;">{{ invoice?.totalAmount + invoice?.totalTax | currency }}</div>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div >
        <!-- invoice -->
        <table>
          <thead>
            <tr class="invoice-header">
              <th class="column-small-cell" style="text-align: center;padding-right: 5px;">QTY</th>
              <th style="text-align: left; padding-left: 5px;">DESCRIPTION</th>
              <th *ngIf="isCustomerWithSRP" style="text-align: right;padding-right: 5px;">RETAIL</th>
              <th style="text-align: right;padding-right: 5px;">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr class="invoice-items" *ngFor="let transaction of invoice?.items">
              <td style="text-align: center;padding-right: 5px; vertical-align: top;"> {{transaction.quantity}} </td>
              <td style="text-align: left;padding-left: 5px; vertical-align: top;"> {{ transaction.description ? transaction.description : transaction.productName }}
                <span *ngIf="utils.isBarcodeValid(transaction.barcode)">
                    <app-barcode-generator *ngIf="isSmallReceipt" [barcodeOptions]="barcodeOptions" [value]="transaction?.barcode" ></app-barcode-generator>
                </span>
              </td>
              <td *ngIf="isCustomerWithSRP" style="text-align: right;padding-right: 5px; vertical-align: top;"> {{transaction.unitPerCase * transaction.suggestedRetailPrice * transaction.quantity | currency:' ' }} </td>
              <td style="text-align: right;padding-right: 5px; vertical-align: top;"> {{transaction.price * transaction.quantity | currency:' ' }} <span >{{ transaction.isTaxable ? 'T' : '' }}</span></td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="invoice-footer">
              <td style="text-align: center;padding-right: 5px;"> {{ totalQuantity }} </td>
              <td style="text-align: left;padding-left: 5px;">{{ isBarCodeShow ? '' : 'TOTAL ITEMS' }}</td>
              <td *ngIf="isCustomerWithSRP" style="text-align: right;padding-right: 5px;"> {{ retailTotal | currency }} </td>
              <td style="text-align: right;padding-right: 5px;"> {{ totalPrice | currency }} </td>
            </tr>
          </tfoot>
        </table>

        <!-- invoice summary -->
        <table>
          <tr>
            <td class="summary-table-key"> Total  </td>
            <td class="summary-table-value"> {{ invoice?.totalAmount | currency  }} </td>
          </tr>
          <tr>
              <td class="summary-table-key"> Sales Tax  </td>
              <td class="summary-table-value"> {{ invoice?.totalTax | currency  }} </td>
          </tr>
          <tr>
              <td class="summary-table-key"> Net Amount  </td>
              <td class="summary-table-value"> {{ invoice?.totalTax + invoice?.totalAmount | currency  }} </td>
          </tr>
        </table>
        <!-- invoice summary -->

        <!-- opening balance and disclaimer -->
        <table>
          <tr *ngIf="invoice?.customer?.openBalance && invoice?.orderType !== orderType.SalesOrder && invoice?.company.businessType !==  businessType.B2C " style="text-align:center;">
            <td class="opening-balance-text">
              CURRENT OPEN BALANCE TOTAL: {{ invoice?.customer?.openBalance | currency}}
            </td>
          </tr>
          <tr *ngIf="invoice?.memo">
            <td>
              <div class="disclaimer-heading">
                MEMO:
              </div>
              <p class="memo-text">
                {{ invoice?.memo }}
              </p>
            </td>
          </tr>
          <tr *ngIf="isOTOCustomer">
            <td>
              <div class="disclaimer-heading">
                DISCLAIMER:
              </div>
              <p *ngIf="!invoice?.company?.disclaimer" class="disclaimer-text">
                ALL RETURN CHECKS ARE SUBJECT TO A $50.00 RETURN FEE.
                BY ACCEPTING THIS INVOICE CUSTOMER AGREE TO PAY {{ invoice?.company?.name | uppercase }} FOR THIS INVOICE IN FULL ACCORDING TO THEIR TERMS.
                A SERVICE CHARGE OF 18% APR PER MONTH WILL BE ADDED TO ALL PAST DUE INVOICES CUSTOMER WILL BE RESPONSIBLE FOR ALL LEGAL AND COLLECTION FEES.
                CUSTOMER IS RESPONSIBLE FOR ALL APPLICABLE SALES TAX.
                ALL RETURNS OR EXCHANGES MUST BE MADE WITHIN 30 DAYS WITH A RECEIPT.
              </p>
              <div *ngIf="invoice?.company?.disclaimer" >
                  <div [innerHTML]="invoice?.company?.disclaimer"></div>
              </div>
            </td>
          </tr>
        </table>
        <!-- opening balance and disclaimer -->
      </div>
  </div>
</ng-container>
