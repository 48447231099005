import { ActionReducer, Action } from '@ngrx/store';
import { merge, pick } from 'lodash-es';
import { LocalStorageService } from '@otrack-lib/core/helper-services/local-storage.service';

export function storageMetaReducer<S, A extends Action = Action>(saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService) {

  let onInit = true; // after load/refresh…
  return (reducer: ActionReducer<S, A>) => {
    return function (state: S, action: A): S {
      // get to the nextState.
      //consol.log(action);
      const nextState = reducer(state, action);
      // init the application state.
      if (onInit) {
        onInit = false;
        const savedState = storageService.retrieve(localStorageKey);
        return merge(nextState, savedState);
      }

      // save the next state to the application storage.
      //consol.log(nextState);
      //consol.log(saveKeys);
      // //consol.log(JSON.stringify(nextState));
      let keys = saveKeys ? saveKeys[0] : [];
      const stateToSave = pickByKeys(nextState, keys as Array<string>); //not working
      //consol.log(stateToSave);
      storageService.store(localStorageKey, stateToSave); //restore this when pick works
      //storageService.setSavedState(nextState, localStorageKey); // disable this when pick works
      return nextState;
    };
  };
}

function pickByKeys(obj: any, keys: Array<string>): any {
  const ret: any = {};
  if (!obj || !keys) return obj;

  const Objkeys = Object.keys(obj);

  ////consol.log(Objkeys);
  keys.forEach(key => {
    if (Objkeys.findIndex(c => c === key) >= 0) {
      //consol.log(key);
      ret[key] = obj[key];
    }
  });
  return ret;
}

