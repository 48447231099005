import { BusinessType } from './../../../../enums/business-type.enum';
import {IInvoice} from '@otrack-lib/models/order/invoice.model';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OrderTypes} from '@otrack-lib/enums';
import {InvoiceService} from '@otrack-lib/core/services/invoice.service';
import {UtilsService} from '@otrack-lib/core/helper-services/utils.service';

@Component({
  selector: 'app-printreceipt',
  templateUrl: './printreceipt.component.html',
  styleUrls: ['./printreceipt.component.scss']
})
export class PrintReceiptComponent implements OnInit {
  public invoice: IInvoice;
  // TODO: not in use, may be remove it ?
  public displayedColumns: string[] = ['qty', 'item', 'unitprice', 'cost'];
  // TODO: not in use, may be remove it ?
  public displayedColumns2: string[] = ['key', 'value'];
  public orderType = OrderTypes;
  public isCustomerWithSRP = false;
  public isBarCodeShow = false;
  public isSmallReceipt = true;
  public isOTOCustomer = false;
  public totalQuantity = 0;
  public totalPrice = 0;
  public retailTotal = 0;
  public barcodeOptions = {};
  public businessType = BusinessType;

  constructor(
    private route: ActivatedRoute,
    public utils: UtilsService,
    protected invoiceService: InvoiceService
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.params['data']) {
      this.loadInvoice(this.route.snapshot.params['data'].split(','));
    }
  }

  loadInvoice(data: string[]) {
    const orderId: number = +data[2];
    if (data[0] === '1') {
      this.invoiceService.getOrder(orderId).subscribe(res => {
        if (res) {
          this.invoice = res;
          this.onInvoiceReady();
        }
      });
    } else {
      this.invoiceService.getSalesOrder(orderId).subscribe(res => {
        this.invoice = res;
        this.invoice.orderType = OrderTypes.SalesOrder;
        this.onInvoiceReady();
      });
    }
  }

  onInvoiceReady() {
    this.barcodeOptions = {
      width: 1,
      height: 22,
      fontSize: 10,
      elementType: 'img'
    };

    this.isCustomerWithSRP = this.invoice.totalSuggestedRetailPrice > 0;

    // add SRP columns
    if (this.isCustomerWithSRP) {
      this.displayedColumns.splice(2, 0, 'suggestedRetailPrice');
      this.displayedColumns.splice(3, 0, 'retail');
    }

    // add barcode column
    if (this.isBarCodeShow) {
      this.displayedColumns.splice(1, 0, 'barcode');
    }

    this.calculatTotals();
    this.updateOTOCustomer();
  }

  calculatTotals() {
    if (this.invoice) {
      this.invoice.items.forEach(c => {
        if (c.quantity > 0) { this.totalQuantity += c.quantity; }

        this.totalPrice += c.price * c.quantity;
      });

      this.retailTotal = this.invoice.totalSuggestedRetailPrice;
    }
  }

  updateOTOCustomer() {
    this.isOTOCustomer = this.invoice.isQBCustomer ? true : false;
  }
}
