import { createAction, props } from '@ngrx/store';
import { ICategoryProduct, IQuickProductDetail } from '@otrack-lib/models/product/quick-order.models';
import { IOrder } from '@otrack-lib/models/order/order.model';
import { IVendor } from '@otrack-lib/models/vendor/vendor.model';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';


export const loadPurchaseOrder = createAction(
  '[Purchase-Order-Edit] Load Purchase Order',
  props<{data: number}>()
);

export const loadBill = createAction(
  '[Purchase-Order-Edit] Load Bill',
  props<{data: number}>()
);

export const loadPurchaseOrderSuccess = createAction(
  '[Purchase-Order-Edit] Load Purchase Order Success',
  props<{data: IInvoice}>()
);

export const loadPurchaseOrderFailure = createAction(
  '[Purchase-Order-Edit] Load Purchase Order Failure',
  props<{error: any}>()
);

export const loadPreferredList = createAction(
  '[Purchase-Order] Load Prefered Vendor Products'
);

export const loadPreferredListSuccess = createAction(
  '[Purchase-Order]  Load Prefered Vendor Products Success',
  props<{ data: ICategoryProduct[] }>()
);

export const loadPreferredListFailure = createAction(
  '[Purchase-Order]  Load Prefered Vendor Products Failure',
  props<{ error: any }>()
);

export const updateOrderByProduct = createAction(
  '[Purchase-Order]  Set Current Order by Product',
  props<{ data: {product: IQuickProductDetail}}>()
);

export const updateOrderByOrder = createAction(
  '[Purchase-Order]  Set Current Order by Order',
  props<{ data: {order: IOrder}}>()
);

export const updateOrderSuccess = createAction(
  '[Purchase-Order]  Set Current Order Success',
  props<{ data: IOrder[]}>()
);

export const setTaxApplyOnOrder = createAction(
  '[Purchase-Order]  Set Tax Apply',
  props<{ data: boolean}>()
);

export const resetOrderOnly = createAction(
  '[Purchase-Order]  Reset order related information after submit'
);

export const resetAll = createAction(
  '[Purchase-Order]  Reset all'
);

export const setMemo = createAction(
  '[Purchase-Order]  Set Memo',
   props<{ data: string}>()
);

export const postPurchaseOrder = createAction(
  '[Purchase-Order]  Submit Purchase Order to Server',
   props<{ data: number}>()
);

export const updatePurchaseOrderToBill = createAction(
  '[Purchase-Order]  update Purchase Order to  Bill Server',
   props<{ data: number}>()
);

export const postBill = createAction(
  '[Purchase-Order]  Submit Purchase Bill to Server',
);

export const postOrderSuccess = createAction(
  '[Purchase-Order]  Submit Purchase Order to Server- Success',
  props<{ data: IInvoice}>()
);

export const postOrderFailure = createAction(
  '[Purchase-Order]  Submit Purchase Order to Server- Failure',
  props<{ error: any}>()
);
