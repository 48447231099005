import { OrderTypes } from './../../../../../../libs/enums/ordertype.enum';
import { IInvoice } from '@otrack-lib/models/order/invoice.model';
import { IOrder } from '@otrack-lib/models/order/order.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './order.actions';
import { ICategoryProduct } from '@otrack-lib/models/product/quick-order.models';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { DiscountType } from '@otrack-lib/enums/discount-type.enum';

export const OrderKey = 'order';

export interface OrderState {
  preferedProductList: ICategoryProduct[];   // Customer Preferred Order for QuickOrder
  currentOrder: IOrder[];                    // Hold current Order list
  currentOrderType: OrderTypes;
  applyTaxOnOrder: boolean;                  // use for updating tax relatd info
  applyDiscountOnOrder: {                    // use for updating discount relatd info
    discountType: DiscountType,
    discountValue: number
  };
  postOrderSuccess: boolean;                 // use to track postOrderSuccess
  postOrderError: ErrorModel;                // use to track portOrderErrors
  postedOrder: IInvoice;                     // use to store last posted Invoice for orderSubmitPage
  invoiceNumber: string;
  memo: string;                              // use to store memo
  error: ErrorModel;
  lastUpdateTimeStamp?: Date;
  success: boolean;
  loading: boolean;
  editOrderId: number;
  loadOrderSuccess: boolean;
}

export const initialState: OrderState = {
  preferedProductList: [],
  currentOrder: [],
  currentOrderType: null,
  invoiceNumber: null,
  // selectedVendorId: 0,
  applyTaxOnOrder: false,
  applyDiscountOnOrder: { discountType: DiscountType.None, discountValue: 0 },
  postOrderSuccess: false,
  postOrderError: null,
  postedOrder: null,
  memo: '',
  error: null,
  lastUpdateTimeStamp: new Date(),
  loading: false,
  success: false,
  editOrderId: 0,
  loadOrderSuccess: false
};

const Reducer = createReducer(
  initialState,
  on(Actions.loadPreferredList, state => ({ ...state, loading: true, success: false })),
  on(Actions.loadPreferredListSuccess, (state, { data }) => ({ ...state, preferedProductList: data, success: true, loading: false })),
  on(Actions.loadPreferredListFailure, (state, { error }) => ({ ...state, error, loading: false, success: false })),
  // update order by product
  on(Actions.updateOrderByProduct, (state) => ({ ...state, lastUpdateTimeStamp: new Date(), loading: true })),
  // update order by order
  on(Actions.updateOrderByOrder, (state) => ({ ...state, lastUpdateTimeStamp: new Date(), loading: true })),
  // update order by product success
  on(Actions.updateOrderSuccess, (state, { data }) => ({ ...state, loading: false, currentOrder: data })),

  on(Actions.setMemo, (state, { data }) => ({ ...state, lastUpdateTimeStamp: new Date(), memo: data })),

  on(Actions.setTaxApplyOnOrder, (state, { data }) => ({ ...state, lastUpdateTimeStamp: new Date(), applyTaxOnOrder: data })),

  on(Actions.applyDiscount, (state, { data }) => ({ ...state, lastUpdateTimeStamp: new Date(), applyDiscountOnOrder: data })),

  on(Actions.resetOrderOnly, state => ({
    ...state, preferedProductList: [], currentOrder: [], memo: '',
    editOrderId: 0, loadOrderSuccess: false, postOrderError: null,
    postOrderSuccess: false,
    applyTaxOnOrder: false,
    applyDiscountOnOrder: { discountType: DiscountType.None, discountValue: 0 },
    error: null
  })), // reset everything except postedOrderStatus and PostedInvoice

  on(Actions.resetAll, state => ({
    preferedProductList: [],
    currentOrder: [],
    currentOrderType: null,
    applyTaxOnOrder: false,
    applyDiscountOnOrder: { discountType: DiscountType.None, discountValue: 0 },
    postOrderSuccess: false,
    postOrderError: null,
    postedOrder: null,
    memo: '',
    invoiceNumber: null,
    error: null,
    lastUpdateTimeStamp: new Date(),
    loading: false,
    success: false,
    editOrderId: 0,
    loadOrderSuccess: false
  })
  ),  // reset everything except postedOrderStatus and PostedInvoice

  on(Actions.postSaleOrder, state => ({
    ...state, loading: true, success: false, currentOrderType: OrderTypes.SalesOrder,
    postOrderError: null, postedOrder: null, postOrderSuccess: false
  })),
  on(Actions.postInvoice, state => ({
    ...state, loading: true, success: false, postOrderError: null,
    postedOrder: null, postOrderSuccess: false
  })),
  on(Actions.postOrderSuccess, (state, { data }) => ({
    ...state, loading: false, success: true,
    postOrderError: null, postedOrder: data, postOrderSuccess: true
  })),
  on(Actions.postOrderFailure, (state, { error }) => ({
    ...state, loading: false, success: false,
    postOrderError: error, postedOrder: null, postOrderSuccess: false
  })),
  on(Actions.setCurrentOrderType, (state, { data }) => ({ ...state, currentOrderType: data.orderType })),
  on(Actions.loadSaleOrder, state => ({ ...state, loading: true, success: false, loadOrderSuccess: false })),
  on(Actions.loadInvoice, state => ({ ...state, loading: true, success: false, loadOrderSuccess: false })),
  on(Actions.loadSaleOrderSuccess, (state, { data }) => ({
    ...state,
    currentOrder: data.items,
    editOrderId: data.orderId,
    memo: data.memo,

    applyDiscountOnOrder: ({ discountType: data.discountType as DiscountType, discountValue: data.discountValue}),
    applyTaxOnOrder: data.isTaxable,
    invoiceNumber: data.invoiceNumber,
    loadOrderSuccess: true, success: true, loading: false
  })),
  on(Actions.loadSaleOrderFailure, (state, { error }) => ({ ...state, error, loading: false, success: false })),
);

export function reducer(state: OrderState | undefined, action: Action) {
  return Reducer(state, action);
}




// import { createFeatureSelector, createSelector } from '@ngrx/store';
// import { OrderActions, OrderActionTypes } from './order.actions';
// import { IOrder } from '@otrack-lib/models/order/order.model';
// import { ErrorModel } from '@otrack-lib/models/error.model';
// import { IInvoice } from '@otrack-lib/models/order/invoice.model';



// export interface State {
//   currentOrderList: IOrder[];
//   isTaxApply: boolean | null;
//   taxId: number | null;
//   memo: string | null;
//   lastUpdateTimeStamp?: Date;
//   error: ErrorModel;
//   success: boolean;
//   loading: boolean;
//   editPurchaseOrderId: number;
//   loadPurchaseOrderSuccess: boolean;
//   postOrderSuccess: boolean;                 // use to track postOrderSuccess
//   postOrderError: ErrorModel;                // use to track portOrderErrors
//   postedOrder: IInvoice;                      // use to store last posted Invoice for orderSubmitPage
// }



// const initialState: State = {
//   currentOrderList: [],
//   taxId: null,
//   isTaxApply: null,
//   memo: null,
//   lastUpdateTimeStamp: new Date(),
//   error: null,
//   loading: false,
//   success: false,
//   editPurchaseOrderId: 0,
//   loadPurchaseOrderSuccess: false,
//   postOrderSuccess: false,
//   postOrderError: null,
//   postedOrder: null,
// };


// export function reducer(state: State = initialState, action: OrderActions): State {

//   switch (action.type) {
//     case OrderActionTypes.SetCurrentOrderList:
//       return {
//         ...state,
//         currentOrderList: action.payload,
//         lastUpdateTimeStamp: new Date(),
//       };

//     case OrderActionTypes.UpsetCurrentOrder:
//       return {
//         ...state,
//         currentOrderList: addOrUpdateOrder(state, action.payload, action.fromQuickOrder),
//         lastUpdateTimeStamp: new Date()
//       };

//     case OrderActionTypes.AddNewItemOrder:
//       return {
//         ...state,
//         currentOrderList: addOrUpdateOrder(state, action.payload),
//         lastUpdateTimeStamp: new Date()
//       };

//     case OrderActionTypes.DeleteOrder:
//       return {
//         ...state,
//         currentOrderList: state.currentOrderList ? state.currentOrderList
//                           // tslint:disable-next-line:max-line-length
//                           .filter(od => od.tempId !== action.payload.tempId && od.productId === action.payload.productId) : state.currentOrderList,
//         lastUpdateTimeStamp: new Date(),
//       };

//     case OrderActionTypes.ClearZeroQtyOrder:
//       return {
//         ...state,
//         currentOrderList: state.currentOrderList ? state.currentOrderList.filter(od => od.quantity !== 0) : state.currentOrderList,
//       };

//     case OrderActionTypes.SetTaxId:
//       return {
//         ...state,
//         taxId: action.payload,
//       };

//     case OrderActionTypes.SetOrdreMemo:
//       return {
//         ...state,
//         memo: action.payload,
//       };

//     case OrderActionTypes.SetIsTaxApply:
//       return {
//         ...state,
//         isTaxApply: action.payload,
//       };

//     case OrderActionTypes.ResetOrderOnly:  // reset everything except postedOrderStatus and PostedInvoice
//         return {...state, currentOrderList: [], memo: '',
//         editPurchaseOrderId: 0, loadPurchaseOrderSuccess: false, postOrderError: null,
//         postOrderSuccess: false,
//         isTaxApply: false, error: null };


//     case OrderActionTypes.ClearCurrentOrder:
//       return { ...state, currentOrderList: [] };

//     default:
//       return state.currentOrderList === null ? { ...state, currentOrderList: [] } : state;
//   }
// }

// function addOrUpdateOrder(state: State, order: IOrder, fromQuickOrder: boolean = false): IOrder[] {
//   const currentOrderList = state.currentOrderList || [];
//   const index = currentOrderList.findIndex(c => c.tempId === order.tempId && c.productId === order.productId);
//   if (index > -1) {
//     return currentOrderList.map(
//       item => order.tempId === item.tempId && order.productId === item.productId
//         ? prepareOrder(order, item, fromQuickOrder)
//         : item
//     );
//   }

//   if (order.quantity !== 0) {
//     let count = fixMaxTempId(currentOrderList);
//     order.tempId = ++count;
//     return [...currentOrderList, order];
//   }
// }

// function prepareOrder(order: IOrder, originalOrder: IOrder, fromQuickOrder: boolean) {
//   if (fromQuickOrder) {
//     order.isTaxable = originalOrder.isTaxable;
//   }
//   return order;
// }

// function fixMaxTempId(orders: IOrder[]) {
//   return orders
//     .map(o => o.tempId)
//     .reduce((a, b) => Math.max(a, b), 0);
// }

