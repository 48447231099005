import { DiscountType } from './../../../../../libs/enums/discount-type.enum';
import { Injectable } from '@angular/core';
import * as CustomerActions from '@client-app/state/customer-state/customer.actions';
import * as fromCustomer from '@client-app/state/customer-state/customer.reducer';
import * as CustomerSelectors from '@client-app/state/customer-state/customer.selectors';
import * as OrderActions from '@client-app/state/order-state/order.actions';
// NGRX
import * as fromOrder from '@client-app/state/order-state/order.reducer';
import * as OrderSelectors from '@client-app/state/order-state/order.selectors';
import { select, Store } from '@ngrx/store';
import { InvoiceService } from '@otrack-lib/core/services/invoice.service';
import { OrderTypes } from '@otrack-lib/enums';
import { IOrder } from '@otrack-lib/models/order/order.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICustomer } from './../../../../../libs/models/customer/customer.model';
import { IInvoice } from './../../../../../libs/models/order/invoice.model';
import { ICategoryProduct, IQuickProductDetail } from './../../../../../libs/models/product/quick-order.models';

@Injectable({ providedIn: 'root' })
export class OrderFacadeService {

  constructor(private store: Store<fromOrder.OrderState>,
    private customerStore: Store<fromCustomer.CustomerState>,
    private invoiceService: InvoiceService) {
  }

  getCurrentOrderList(): Observable<IOrder[]> {
    return this.store.pipe(select(OrderSelectors.getCurrentOrderList));
  }

  isTaxApplied(): Observable<boolean> {
    return this.store.pipe(select(OrderSelectors.isTaxApplied));
  }

  getMemo(): Observable<string> {
    return this.store.pipe(select(OrderSelectors.getMemo));
  }

  getDiscount(): Observable<{
    discountType: DiscountType;
    discountValue: number;
  }> {
    return this.store.pipe(select(OrderSelectors.getDiscount));
  }

  getEditOrderId(): Observable<number> {
    return this.store.pipe(select(OrderSelectors.getEditOrderId));
  }

  getEditInvoiceNumer(): Observable<string> {
    return this.store.pipe(select(OrderSelectors.getEditInvoiceNumber));
  }


  getCurrentCustomer(): Observable<ICustomer> {
    return this.customerStore.pipe(select(CustomerSelectors.getCurrentCustomer));
  }


  getCustomerTaxPercent(): Observable<number> {
    return this.customerStore.pipe(select(CustomerSelectors.getCustomerTaxPercent));
  }

  getLastPostInvoice(): Observable<IInvoice> {
    return this.store.pipe(select(OrderSelectors.getPostedInvoice));
  }

  getCurrentOrderType(): Observable<OrderTypes> {
    return this.store.pipe(select(OrderSelectors.getCurrentOrderType));
  }

  isOrderPostedFailure(): Observable<boolean> {
    return this.store.pipe(select(OrderSelectors.isOrderPostedFailure), map(d => d.status));
  }


  isOrderPostedScuccess(): Observable<boolean> {
    return this.store.pipe(select(OrderSelectors.isOrderPostedSuccess));
  }

  getLoadingStatus(): Observable<boolean> {
    return this.store.pipe(select(OrderSelectors.getLoadingStatus));
  }


  getCustomerPreferredList(): Observable<ICategoryProduct[]> {
    return this.store.pipe(select(OrderSelectors.getCustomerPreferredList));
  }

  isOrderLoaded(): Observable<boolean> {
    return this.store.pipe(select(OrderSelectors.isOrderLoaded));
  }

  loadSaleOrderWithCustomer(orderId: number, customerId: number) {
    this.store.dispatch(OrderActions.loadSaleOrder({ data: orderId }));
    this.store.dispatch(CustomerActions.loadCurrentCustomer({ data: customerId }));
  }

  loadInvoiceWithCustomer(invoiceId: number, customerId: number) {
    this.store.dispatch(OrderActions.loadInvoice({ data: invoiceId }));
    this.store.dispatch(CustomerActions.loadCurrentCustomer({ data: customerId }));
  }

  loadCustomerPreferredList() {
    //this.store.dispatch(new OrderActions.UpsetCurrentOrder(order, fromQuickOrder));
    this.store.dispatch(OrderActions.loadPreferredList());
  }

  setCurrentCustomer(customer: ICustomer) {
    //this.store.dispatch(new OrderActions.UpsetCurrentOrder(order, fromQuickOrder));
    this.store.dispatch(CustomerActions.setCurrentCustomer({ data: customer }));
  }

  updateOrder(order: IOrder, fromQuickOrder: boolean = false) {
    //this.store.dispatch(new OrderActions.UpsetCurrentOrder(order, fromQuickOrder));
    this.store.dispatch(OrderActions.updateOrderByOrder({ data: { order: order } }));
  }

  updateOrderByProduct(product: IQuickProductDetail) {
    //this.store.dispatch(new OrderActions.UpsetCurrentOrder(order, fromQuickOrder));
    this.store.dispatch(OrderActions.updateOrderByProduct({ data: { product } }));
  }


  addItemInOrderList(order: IOrder) {
    //this.store.dispatch(new OrderActions.AddNewItemOrder(order));
    this.store.dispatch(OrderActions.updateOrderByOrder({ data: { order: order } }));
  }

  deleteItemFromOrderList(order: IOrder) {
    // this.store.dispatch(new OrderActions.DeleteOrder(order));
    order.quantity = 0;
    this.store.dispatch(OrderActions.updateOrderByOrder({ data: { order: order } }));
  }

  setTaxApplyFlag(isTaxApply: boolean) {
    // this.store.dispatch(new OrderActions.SetIsTaxApply(isTaxApply));
    this.store.dispatch(OrderActions.setTaxApplyOnOrder({ data: isTaxApply }));
  }

  applyDiscount(discount: { discountType: DiscountType, discountValue: number }) {
    this.store.dispatch(OrderActions.applyDiscount({ data: discount }));
  }

  setMemo(memo: string) {
    this.store.dispatch(OrderActions.setMemo({ data: memo }));
  }

  setCurrentOrderType(orderType: OrderTypes) {
    this.store.dispatch(OrderActions.setCurrentOrderType({ data: { orderType } }));
  }

  postSaleOrder(saleOrderId: number) {
    this.store.dispatch(OrderActions.postSaleOrder({ data: saleOrderId }));
  }


  postInvoice(orderType: OrderTypes, invoiceId: number = 0) {
    this.store.dispatch(OrderActions.postInvoice({ data: { orderId: invoiceId, orderType } }));
  }

  convertSaleOrderToInvoice(saleOrderId: number) {
    this.store.dispatch(OrderActions.updateSaleOrderToInvoice({ data: saleOrderId }));

  }

  resetCustomer() {
    this.customerStore.dispatch(CustomerActions.clearCurrentCustomer());
  }

  resetOrderOnly() {
    this.store.dispatch(OrderActions.resetOrderOnly());
  }

  resetAll() {
    this.store.dispatch(OrderActions.resetAll());
    this.resetCustomer();
  }

}
