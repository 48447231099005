<h1 mat-dialog-title class="dialog-headig"> <i  style="color:green" class="material-icons icon">info</i> Unsaved Order Found</h1>
<div mat-dialog-content>
  <span style="font-size: 0.8rem;">We have found one incomplete invoice for customer <b> {{ session.customer?.customerName }} </b>.
     Invoice last updated on <b> {{ session.lastUpdateTimeStamp | date:'dd MMM, yyyy h:mm a' }}  </b></span>
  <p>Do you want to continue with unsaved order ?</p>
</div>
<div mat-dialog-actions>
  <button class="btn btn-warning" style=" margin-right: 5px" mat-raised-button color="warn" (click)="discard()">Discard</button>
  <button class="btn btn-success" mat-raised-button color="primary" (click)="restore()" [mat-dialog-close]="session" cdkFocusInitial>Restore Session</button>
</div>

