import { getCurrentCustomer } from './state/customer-state/customer.selectors';
import { OrderFacadeService } from '@client-app/services/order-facade.service';
import { UserService } from '@otrack-lib/core/services/user.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

import { MatDialog } from '@angular/material';
import { SessionRestoreDialog } from './shared/model/session-restore.dialog';
import { InvoiceState } from './common/models';
import { InMemoryService } from './services/inMemoryinvoice.service';

//NGRX
import { Store, select } from '@ngrx/store';
import * as UserActions from './state/user-state/user.actions';
import * as CustomerActions from './state/customer-state/customer.actions';
import * as OrderActions from '@client-app/state/order-state/order.actions';
import * as fromCustomer from './state/customer-state/customer.reducer';
import * as fromUser from './state/user-state/user.reducer';
import * as fromOrder from './state/order-state/order.reducer';
import * as fromOrderSelector from './state/order-state/order.selectors';
import { CompanyService } from '@otrack-lib/core/services/company.service';

import { State } from './state';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'otrack-io';
  tempInvoiceState:InvoiceState = new InvoiceState();

  constructor(private router: Router,
               private store: Store<State>,
               private orderFacadeService: OrderFacadeService,
              private memoryService: InMemoryService,
              private companyService: CompanyService,
              private userService: UserService,
              public dialog: MatDialog,) {
  }

 async ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    await this.store.pipe(select(fromUser.getCurrentUserName)).subscribe(username => {
      this.tempInvoiceState.userName = username;
      //consol.log('in component', username);
    });

    // 1st call to get companyInfo
    //this.companyService.getCompanyDetail().subscribe();
//debugger;

    await this.userService.getUser().pipe(filter(c => c !== undefined)).subscribe( currentUser => {
      if (this.tempInvoiceState.userName === currentUser.username) { // check with userService and current Oder exist

        // load customer and load order
        // this.store.pipe(select(fromCustomer.getCurrentCustomer))
        this.orderFacadeService.getCurrentCustomer().subscribe(cust => {
          this.tempInvoiceState.customer = cust;
        });

        this.store.pipe(select(fromOrderSelector.getOrderState)).subscribe(orders => {
          this.tempInvoiceState.invoiceOrders = orders.currentOrder;
          this.tempInvoiceState.lastUpdateTimeStamp = orders.lastUpdateTimeStamp;
          this.tempInvoiceState.memo = orders.memo;
          this.tempInvoiceState.isTaxApply = orders.applyTaxOnOrder;
        });

       if (this.tempInvoiceState.invoiceOrders.length > 0) {
          this.confirmStateRestoreDialog(this.tempInvoiceState);
        }

      } else{
        // this.store.dispatch(new CustomerActions.ClearCurrentCustomer());
        // this.store.dispatch(new OrderActions.ClearCurrentOrder());
        this.orderFacadeService.resetAll();
        this.store.dispatch(new UserActions.SetUserName(currentUser.username));
      }
    });

  }

  confirmStateRestoreDialog(stateSession: InvoiceState): void {
    const dialogRef = this.dialog.open(SessionRestoreDialog, {
      // tslint:disable-line
      disableClose: true,
      data: stateSession
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.memoryService.restoreSession(result);  // Remove this in future after replace ngrx
        const redirect = '/order/orderview';
        this.router.navigate([redirect]);
      } else {
       // this._stateManagerService.clearAllState();
        //this.store.dispatch(new CustomerActions.ClearCurrentCustomer());
        this.orderFacadeService.resetAll();
        const redirect = '/home';
        this.router.navigate([redirect]);
      }
    });
  }

}

