import { UserService } from '@otrack-lib/core/services/user.service';
import { ErrorCodes } from './../enums/error-code.enums';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { ConfigurationService } from './helper-services/configuration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from './services/error.service';
import { NotificationService } from './helper-services/notification.service';
import { AuthService } from './services/auth.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  configurationService: ConfigurationService;
  authService: AuthService;
  constructor(private injector: Injector) {
    this.configurationService = this.injector.get(ConfigurationService);
    this.authService = this.injector.get(AuthService);
    Sentry.init({
      dsn: this.configurationService.config.sentryDsn,
    });
  }

  async handleError(error: Error | HttpErrorResponse) {

    const errorService = this.injector.get(ErrorService);
    const notifier = this.injector.get(NotificationService);

    let message;
    let sentryMessage;
    if (error instanceof HttpErrorResponse) {
      // Server error
      // message = errorService.getServerErrorMessage(error);
      // sentryMessage = errorService.getServerErrorMessage(error);
      // notifier.showError(message);
    } else {

      // Client Error
      const err = this.getErrorModel(error);

      if (err && err.code === ErrorCodes.USER_NOT_FOUND) { // in case of usernot found redirect to login Page by sigOut
        await this.authService.logout();
        message = errorService.getClientErrorMessage(err ? err : error);
        notifier.showError(message);
      }

      message = errorService.getClientErrorMessage(err ? err : error);
      notifier.showError(message);
      sentryMessage = error;
    }

    // capture sentry logging in case of production only
    if (this.configurationService.config.production) {
      Sentry.captureException(sentryMessage);
    }

    console.error(error);
  }


  getErrorModel(error: any): ErrorModel {
    let errorModel: ErrorModel;
    try {
      errorModel = JSON.parse(error.message);
    } catch (e) {
      if (error.rejection instanceof ErrorModel) {
        return error.rejection;
      }
      return errorModel;
    }
    return errorModel;
  }
}
