
import { IInvoice } from '@otrack-lib/models/order/invoice.model';

import { IOrder } from '@otrack-lib/models/order/order.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './purchase-order.actions';
import { ICategoryProduct } from '@otrack-lib/models/product/quick-order.models';
import { ErrorModel } from '@otrack-lib/models/error.model';

export const PurchaseOrderKey = 'purchaseOrder';

export interface PurchaseOrderState {
   preferedProductList: ICategoryProduct[];   // Vendor Preferred Order for QuickPO
   currentPOOrder: IOrder[];                  // Hold current Order list
   applyTaxOnOrder: boolean;                  // use for updating tax relatd info
   postOrderSuccess: boolean;                 // use to track postOrderSuccess
   postOrderError: ErrorModel;                // use to track portOrderErrors
   postedOrder: IInvoice;                      // use to store last posted Invoice for orderSubmitPage
   memo: string;                              // use to store memo
   error: ErrorModel;
   success: boolean;
   loading: boolean;
   editPurchaseOrderId: number;
   loadPurchaseOrderSuccess: boolean;
  // preferedListLoaded: boolean;               // use to track Preferred Order loaded or not
}

export const initialState: PurchaseOrderState = {
  preferedProductList : [],
  currentPOOrder: [],
 // selectedVendorId: 0,
  applyTaxOnOrder: false,
  postOrderSuccess: false,
  postOrderError: null,
  postedOrder: null,
  memo: '',
  error: null,
  loading: false,
  success: false,
  editPurchaseOrderId: 0,
  loadPurchaseOrderSuccess: false
  // preferedListLoaded: false
};

const Reducer = createReducer(
  initialState,
  on(Actions.loadPreferredList, state => ({...state, loading: true, success: false})),
  on(Actions.loadPreferredListSuccess, (state, {data}) => ({...state, preferedProductList: data, success: true, loading: false })),
  on(Actions.loadPreferredListFailure, (state, { error }) => ({...state, error, loading: false, success: false})),
  // update order by product
  on(Actions.updateOrderByProduct, (state) => ({...state, loading: true})),
  // update order by order
  on(Actions.updateOrderByOrder, (state) => ({...state, loading: true})),
  // update order by product success
  on(Actions.updateOrderSuccess, (state, { data }) => ({...state, loading: false, currentPOOrder: data})),

  on(Actions.setMemo, (state, { data }) => ({...state, memo: data})),

  on(Actions.setTaxApplyOnOrder, (state, { data }) => ({...state, applyTaxOnOrder: data})),

  on(Actions.resetOrderOnly, state => ({...state, preferedProductList: [], currentPOOrder: [], memo: '',
             editPurchaseOrderId: 0, loadPurchaseOrderSuccess: false, postOrderError: null,
             postOrderSuccess: false,
             applyTaxOnOrder: false, error: null })), // reset everything except postedOrderStatus and PostedInvoice

  on(Actions.resetAll, () => ({...initialState })),  // reset everything except postedOrderStatus and PostedInvoice

  on(Actions.postPurchaseOrder, state => ({...state, loading: true, success: false,
    postOrderError: null, postedOrder: null, postOrderSuccess: false})),
  on(Actions.postBill, state => ({...state, loading: true, success: false, postOrderError: null,
     postedOrder: null, postOrderSuccess: false})),
  on(Actions.postOrderSuccess, (state, { data }) => ({...state, loading: false, success: true,
    postOrderError: null, postedOrder: data, postOrderSuccess: true})),
  on(Actions.postOrderFailure, (state, { error }) => ({...state, loading: false, success: false,
    postOrderError: error, postedOrder: null, postOrderSuccess: false})),

  on(Actions.loadPurchaseOrder, state => ({...state, loading: true, success: false, loadPurchaseOrderSuccess: false})),
  on(Actions.loadBill, state => ({...state, loading: true, success: false, loadPurchaseOrderSuccess: false})),
  on(Actions.loadPurchaseOrderSuccess, (state, {data}) => ({...state, currentPOOrder: data.items,
    editPurchaseOrderId: data.orderId, memo: data.memo, applyTaxOnOrder: data.IsTaxable,
    loadPurchaseOrderSuccess: true, success: true, loading: false })),
  on(Actions.loadPurchaseOrderFailure, (state, { error }) => ({...state, error, loading: false, success: false})),
);

export function reducer(state: PurchaseOrderState | undefined, action: Action) {
  return Reducer(state, action);
}
