export const environment = {
  env: 'prod',
  build: 'v9.3.0',
  production: true,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'ce9d77b308c149d5992a80073637e4d5',
  firebase: {
    apiKey: 'AIzaSyBCjwnBimKaKZlRkzHtrGDvXwHe9WU3BMo',
    authDomain: 'otrack-prod.firebaseapp.com',
    databaseURL: 'https://otrack-prod.firebaseio.com',
    projectId: 'otrack-prod',
    storageBucket: 'otrack-prod.appspot.com',
    messagingSenderId: '844360042789',
    appId: '1:844360042789:web:fb9d12be5d442bcab8b2cc',
    measurementId: 'G-VYG5PH3D6V'
  },
  mode: 'Production Environment',
  apiURL: 'https://api.otrack.io',
  appDomain: '.otrack.io',  // include dot as prefix
  signupFunction: 'https://auth.otrack.io/users_signin',
  checkstatusFunction: 'https://auth.otrack.io/users_checkstatus',
  signoutFunction: 'https://auth.otrack.io/users_signout',
  loginPageUrl: 'https://app.otrack.io/login',
  sentryDsn: '',
  localToken: ''
};
