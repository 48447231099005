
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as OrderStore from './order.reducer';
import * as CustomerSelectors from '@client-app/state/customer-state/customer.selectors';
export const selectState = createFeatureSelector<OrderStore.OrderState>(
  OrderStore.OrderKey
);

export const isCustomerPreferredListExist = createSelector(
  selectState,
  (state: OrderStore.OrderState) => {
    return state.preferedProductList ? state.preferedProductList.length > 0 : false;
  }
);

export const getCustomerPreferredList = createSelector(
  selectState,
  (state: OrderStore.OrderState) => {
    return state.preferedProductList;
  }
);

export const isTaxApplied = createSelector(
  selectState,
  (state: OrderStore.OrderState) => {
    return state.applyTaxOnOrder;
  }
);

export const getCurrentOrderList = createSelector(
  selectState,
  (state: OrderStore.OrderState,) => {
    return state.currentOrder;
  }
);


export const getMemo = createSelector(
  selectState,
  (state: OrderStore.OrderState,) => state.memo
);

export const getDiscount = createSelector(
  selectState,
  (state: OrderStore.OrderState,) => state.applyDiscountOnOrder
);

export const getLoadingStatus = createSelector(
  selectState,
  (state: OrderStore.OrderState) => state.loading
);

export const getPreferredListRelatedInfo = createSelector(
  selectState,
  (state: OrderStore.OrderState) => ({
    currentOrderList: state.currentOrder,
    preferredOrderList: state.preferedProductList
  })
);

export const getOrderInfo = createSelector(
  selectState,
  CustomerSelectors.getCurrentCustomerId,
  (state: OrderStore.OrderState, customerId: number) => ({
    currentOrderList: state.currentOrder,
    isTaxApply: state.applyTaxOnOrder,
    discountType: state.applyDiscountOnOrder.discountType,
    discountValue: state.applyDiscountOnOrder.discountValue,
    memo: state.memo,
    customerId: customerId
  })
);

export const isOrderPostedSuccess = createSelector(
  selectState,
  (state: OrderStore.OrderState) => state.postOrderSuccess
);

export const isOrderPostedFailure = createSelector(
  selectState,
  (state: OrderStore.OrderState) => ({
    status: !state.postOrderSuccess && state.postOrderError !== null,
    error: state.postOrderError
  })
);

export const getPostedInvoice = createSelector(
  selectState,
  (state: OrderStore.OrderState) => state.postedOrder
);


export const isOrderLoaded = createSelector(
  selectState,
  CustomerSelectors.getCustomerSuccessStatus,
  (state: OrderStore.OrderState,
    customerLoadSuccessStatus: boolean) => state.loadOrderSuccess && customerLoadSuccessStatus
);


export const getEditOrderId = createSelector(
  selectState,
  (state: OrderStore.OrderState) => state.editOrderId
);

export const getEditInvoiceNumber = createSelector(
  selectState,
  (state: OrderStore.OrderState) => state.invoiceNumber
);


export const getCurrentOrderType = createSelector(
  selectState,
  (state: OrderStore.OrderState) => state.currentOrderType
);

export const getOrderState = createSelector(
  selectState,
  state => (state),
);

// import { createFeatureSelector, createSelector } from '@ngrx/store';
// import { OrderActions, OrderActionTypes } from './order.actions';
// import { IOrder } from '@otrack-lib/models/order/order.model';
// import { State } from './order.reducer';

// // Selector functions
// const getUserFeatureState = createFeatureSelector<State>('order');

// export const getCurrentOrderList = createSelector(
//   getUserFeatureState,
//   state => state.currentOrderList,
// );

// export const getTaxId = createSelector(
//   getUserFeatureState,
//   state => state.taxId,
// );

// export const getIsTaxApply = createSelector(
//   getUserFeatureState,
//   state => state.isTaxApply,
// );

// export const getOrderState = createSelector(
//   getUserFeatureState,
//   state => (state),
// );

// export const isTaxApplied = createSelector(
//   getUserFeatureState,
//   (state: State) => {
//     return state.isTaxApply;
//   }
// );

// export const getMemo = createSelector(
//   getUserFeatureState,
//   (state: State) => state.memo
// );

