/* NgRx */
import { Action } from '@ngrx/store';

export enum UserActionTypes {
  SetUserName = '[User] Set UserName',
  ResetUserName = '[User] Reset UserName',
  SetCurrentCustomer=  '[Customer] Set Customer',
  ClearCurrentCustomer = '[Customer] Clear Customer',
  SetCurrentOrder = '[Order] Set Order',
  UpdateCurrentOrder = '[Order] Update Current Order',
  ClearCurrentOrder = '[Order] Clear Current Order',
}

export class SetUserName implements Action {
  readonly type = UserActionTypes.SetUserName;
  constructor(public payload: string) { }
}

export class ResetUserName implements Action {
  readonly type = UserActionTypes.ResetUserName;
  constructor() { }
}

export type UserActions = SetUserName | ResetUserName;
