import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { InvoiceState } from '@client-app/common/models';

@Component({
    selector: 'app-session-restore-dialog',
    templateUrl: './session-restore.dialog.html',
    styleUrls: ['./session-restore.dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class SessionRestoreDialog {
    constructor(
        public dialogRef: MatDialogRef<SessionRestoreDialog>,
        public router: Router,
        @Inject(MAT_DIALOG_DATA)
        public session: InvoiceState
    ) {}

    discard(): void {
        this.dialogRef.close();
    }

    restore(): void {
      this.dialogRef.close(this.session);
    }
}
