import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as PurchaseOrderStore from './purchase-order.reducer';
import * as VendorSelectors from '@client-app/state/vendor-state/vendor.selectors';
export const selectState = createFeatureSelector<PurchaseOrderStore.PurchaseOrderState>(
  PurchaseOrderStore.PurchaseOrderKey
);

export const isVendorPreferredListExist = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState) => {
    return state.preferedProductList ? state.preferedProductList.length > 0 : false;
  }
);

export const getVendorPreferredList = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState) => {
    return state.preferedProductList;
  }
);

export const isTaxApplied = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState) => {
    return state.applyTaxOnOrder;
  }
);

export const getCurrentOrderList = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState, ) => {
    return state.currentPOOrder;
  }
);


export const getMemo = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState, ) => state.memo
);

export const getLoadingStatus = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState) => state.loading
);

export const getPreferredListRelatedInfo = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState) => ({currentOrderList: state.currentPOOrder,
                                                      preferredOrderList: state.preferedProductList})
);

export const getOrderInfo = createSelector(
  selectState,
  VendorSelectors.getCurrentVendorId,
  (state: PurchaseOrderStore.PurchaseOrderState, vendorId: number) => ({currentOrderList: state.currentPOOrder,
                                                      isTaxApply: state.applyTaxOnOrder,
                                                      memo: state.memo,
                                                      vendorId: vendorId})
);

export const isOrderPostedSuccess = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState) => state.postOrderSuccess
);

export const isOrderPostedFailure = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState) => ( {status : !state.postOrderSuccess && state.postOrderError !== null,
                                                       error: state.postOrderError })
);

export const getPostedInvoice$ = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState) => state.postedOrder
);


export const isOrderLoaded = createSelector(
  selectState,
  VendorSelectors.getVendorSuccessStatus,
  (state: PurchaseOrderStore.PurchaseOrderState,
          vendorLoadSuccessStatus: boolean) => state.loadPurchaseOrderSuccess && vendorLoadSuccessStatus
);


export const getEditPurchaseOrderId = createSelector(
  selectState,
  (state: PurchaseOrderStore.PurchaseOrderState) => state.editPurchaseOrderId
);
