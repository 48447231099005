import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { InvoiceHistorytableComponent } from './invoice-historytable/invoice-historytable.component';
import { PaymentHistorytableComponent } from './payment-historytable/payment-historytable.component';
import { TransactionHistorytableComponent } from './transacton-historytable/transaction-historytable.component';
import { TransactionMiniDetailComponent } from './component/transaction-mini-detail/transaction-mini-detail.component';
import { CustomerMiniDetailComponent } from './component/customer-mini-detail/customer-mini-detail.component';
import { VendorMiniDetailComponent } from './component/vendor-mini-detail/vendor-mini-detail.component';
import { ExpenseHistorytableComponent } from './expense-historytable/expense-historytable.component';
import { ExpenseMiniDetailComponent } from './component/expense-mini-detail/expense-mini-detail.component';
import { CoreModule } from '@otrack-lib/core/core.module';

@NgModule({
  imports: [
    CommonModule,
    // NgxBarcodeModule,
    CoreModule,
    SharedModule
  ],
  declarations: [
    TransactionHistorytableComponent,
    InvoiceHistorytableComponent,
    PaymentHistorytableComponent,
    CustomerMiniDetailComponent,
    VendorMiniDetailComponent,
    TransactionMiniDetailComponent,
    ExpenseHistorytableComponent,
    ExpenseMiniDetailComponent
  ],
  exports: [
    TransactionHistorytableComponent,
    InvoiceHistorytableComponent,
    PaymentHistorytableComponent,
    CustomerMiniDetailComponent,
    VendorMiniDetailComponent,
    ExpenseHistorytableComponent,
    ExpenseMiniDetailComponent
  ]
})
export class ClientTemplateModule {}
