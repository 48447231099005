
import { ICustomerDetail, ICustomer } from '@otrack-lib/models/customer/customer.model';
import { IOrder } from '@otrack-lib/models/order/order.model';
export class InvoiceState {
    public userName: string;
    public customer: ICustomer;
    public memo?: string;
    public isTaxApply?: boolean;
    public taxId?: number;
    public invoiceOrders?: IOrder[];
    public lastUpdateTimeStamp?: Date;
}
