import { PrintTemplateComponent } from './print-template/print-template.component';
import { PrintZReportComponent } from './print-z-report/print-z-report.component';
import { InvoiceItemListComponent } from './printorder-template1/invoice-itemlist/invoice-itemlist.component';
import { InvoiceFooterComponent } from './printorder-template1/invoice-footer/invoice-footer.component';
import { InvoiceSubHeaderComponent } from './printorder-template1/invoice-subheader/invoice-subheader.component';
import { InvoiceHeaderComponent } from './printorder-template1/invoice-header/invoice-header.component';
import { PrintStockSummaryReportComponent } from './print-stock-summary-report/print-stock-summary-report.component';
import { PrintCustomerItemReportComponent } from './printcustomeritemreport/print-customer-item-report.component';
import { PrintReceiptComponent } from './printreceipt/printreceipt.component';
import { PrintPaymentComponent } from './printpayment/printpayment.component';
import { PrintOrderComponent } from './printorder/printorder.component';
import { PrintOrderTemplate1Component } from './printorder-template1/printorder-template1.component';
import { BarcodeGeneratorModule } from '../barcode-generator/barcode-generator.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@admin-app/shared/shared.module';
import { PrintTransactionReportComponent } from './printtransactionreport/print-transaction-report.component';
import { PrintItemOrderReportComponent } from './printitemorderreport/print-item-order-report.component';
import { PrintAgingReportComponent } from './printagingreport/print-aging-report.component';
import { PrintVendorAgingReportComponent } from './print-vendor-aging-report/print-vendor-aging-report.component';

import { PrintPaymentReportComponent } from './printpaymentreport/print-payment-report.component';
import { PrintItemPurchaseReportComponent } from './printitempurchasereport/print-item-purchase-report.component';
import { PrintVendorItemPurchaseReportComponent } from './print-vendor-item-purchase-report/print-vendor-item-purchase-report.component';
import { PrintOrderCustomComponent } from './printorder-custom/printorder-custom.component';
import { PrintItemDetailReportComponent } from './print-item-detail-report/print-item-detail-report.component';
import { PrintOpenBalanceReportComponent } from './print-open-balance-report/print-open-balance-report.component';
import { PrintShippingSlipComponent } from './print-shipping-slip/print-shipping-slip.component';
import { PrintZReportListComponent } from './print-z-report-list/print-z-report-list.component';

@NgModule({
  imports: [
    CommonModule,
    BarcodeGeneratorModule,
    SharedModule
  ],
  declarations: [
    PrintOrderComponent,
    PrintTemplateComponent, // this component is used to get the template from server
    PrintShippingSlipComponent,
    InvoiceHeaderComponent,
    InvoiceSubHeaderComponent,
    InvoiceItemListComponent,
    InvoiceFooterComponent,
    PrintOrderTemplate1Component,
    PrintOrderCustomComponent,
    PrintPaymentComponent,
    PrintReceiptComponent,
    PrintTransactionReportComponent,
    PrintItemOrderReportComponent,
    PrintAgingReportComponent,
    PrintVendorAgingReportComponent,
    PrintPaymentReportComponent,
    PrintCustomerItemReportComponent,
    PrintStockSummaryReportComponent,
    PrintItemPurchaseReportComponent,
    PrintVendorItemPurchaseReportComponent,
    PrintItemDetailReportComponent,
    PrintOpenBalanceReportComponent,
    PrintZReportComponent,
    PrintZReportListComponent
  ],
  exports: [
    PrintOrderComponent,
    PrintTemplateComponent,
    PrintShippingSlipComponent,
    PrintOrderTemplate1Component,
    PrintOrderCustomComponent,
    PrintPaymentComponent,
    PrintReceiptComponent,
    PrintTransactionReportComponent,
    PrintItemOrderReportComponent,
    PrintAgingReportComponent,
    PrintVendorAgingReportComponent,
    PrintPaymentReportComponent,
    PrintCustomerItemReportComponent,
    PrintStockSummaryReportComponent,
    PrintItemPurchaseReportComponent,
    PrintVendorItemPurchaseReportComponent,
    PrintItemDetailReportComponent,
    PrintOpenBalanceReportComponent,
    PrintZReportComponent,
    PrintZReportListComponent
  ]
})
export class PrintModule { }
