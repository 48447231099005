import { postOrderFailure, loadPurchaseOrder } from './purchase-order.actions';
import { VendorService } from '@otrack-lib/core/services/vendor.service';
import { OrderHandlingService } from './../../services/order-handling.service';
import { PurchaseOrderService } from '@otrack-lib/core/services/purchase-order.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as POActions from './purchase-order.actions';
import * as POSeletors from './purchase-order.selector';
import * as VendorSelectors from '@client-app/state/vendor-state/vendor.selectors';
import { PurchaseOrderState } from './purchase-order.reducer';
import { Store, select } from '@ngrx/store';


@Injectable()
export class PurchaseOrderEffects {

  constructor( private actions$: Actions,
               private orderHandlingService: OrderHandlingService,
               private vendorService: VendorService,
               private store: Store<PurchaseOrderState>,
               private purchaseOrderService: PurchaseOrderService) {}

  /**
   * Load user roles$ of user effects - call the userService to load the userRoles from backend and
   * dispatch loadSuccessful action or failure action in case of error
   */
  loadPreferedPOs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POActions.loadPreferredList),
      withLatestFrom(this.store.pipe(select(VendorSelectors.getCurrentVendorId))),
      map(([action, vendorId]) => vendorId),
      withLatestFrom(this.store.pipe(select(POSeletors.getVendorPreferredList))),
      map(([vendor, list]) => ({ vendorId: vendor, list})),
      switchMap( storeValue => {
          if (storeValue.list && storeValue.list.length > 0) { // means preferred list is already in the store
                return of(POActions.loadPreferredListSuccess({ data: storeValue.list }));
          } else {
          return  this.purchaseOrderService.getVendorPreferedList(storeValue.vendorId).pipe(
              map(data => POActions.loadPreferredListSuccess({ data })),
             catchError(error => of(POActions.loadPreferredListFailure({ error }))));
          }
        }
      )
    );
  });

  updatePreferredOrderList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POActions.loadPreferredListSuccess),
      withLatestFrom(this.store.pipe(select(POSeletors.getCurrentOrderList))),
      map(([action, currentOrderList]) =>
            this.orderHandlingService.updatePreferredOrderUsingCurrentOrderList(action.data, currentOrderList))
    );
  }, {dispatch: false});


  updateCurrentOrderByProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POActions.updateOrderByProduct),
      withLatestFrom(this.store.pipe(select(POSeletors.getCurrentOrderList))),
      map(([action, orderList]) => ({ existingOrderList: orderList, updatedProduct: action.data.product}) ),
      switchMap( updatedOrderObj =>
          of(this.orderHandlingService.updateOrderListByProduct(updatedOrderObj.existingOrderList, updatedOrderObj.updatedProduct))
            .pipe(
              map(data => POActions.updateOrderSuccess({ data })),
            )
      )
    );
  });

  updateCurrentOrderByOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POActions.updateOrderByOrder),
      withLatestFrom(this.store.pipe(select(POSeletors.getCurrentOrderList))),
      map(([action, orderList]) => ({ existingOrderList: orderList, updatedOrder: action.data.order}) ),
      switchMap( updatedOrderObj =>
          of(this.orderHandlingService.updateOrderListByOrder(updatedOrderObj.existingOrderList, updatedOrderObj.updatedOrder))
            .pipe(
              map(data => POActions.updateOrderSuccess({ data })),
            )
      )
    );
  });

  postPurchaseOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POActions.postPurchaseOrder),
      withLatestFrom(this.store.pipe(select(POSeletors.getOrderInfo))),
      map(([action, orderInfo]) => ({order: orderInfo, editOrderId: action.data}) ),
      switchMap( postOrderInfo => {
        if (postOrderInfo.editOrderId > 0) { // means edit purchase order
          return this.purchaseOrderService.updatePOOrderOnServer(postOrderInfo.editOrderId, postOrderInfo.order.vendorId,
                                   postOrderInfo.order.currentOrderList, postOrderInfo.order.memo, postOrderInfo.order.isTaxApply)
          .pipe(
            map(data => POActions.postOrderSuccess({ data })),
            catchError(error => of(POActions.postOrderFailure({ error })))
          );
        } else {
         return this.purchaseOrderService.postPOOrderOnServer(postOrderInfo.order.vendorId, postOrderInfo.order.currentOrderList,
                                   postOrderInfo.order.memo, postOrderInfo.order.isTaxApply)
            .pipe(
              map(data => POActions.postOrderSuccess({ data })),
              catchError(error => of(POActions.postOrderFailure({ error })))
            );
        }
      }
      )
    );
  });


  postPurchaseOrderToBill$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POActions.updatePurchaseOrderToBill),
      withLatestFrom(this.store.pipe(select(POSeletors.getOrderInfo))),
      map(([action, orderInfo]) => ({order: orderInfo, editOrderId: action.data}) ),
      switchMap( postOrderInfo => {
        if (postOrderInfo.editOrderId > 0) { // means edit purchase order
          return this.purchaseOrderService.updatePOOrderOnServer(postOrderInfo.editOrderId, postOrderInfo.order.vendorId,
                      postOrderInfo.order.currentOrderList, postOrderInfo.order.memo, postOrderInfo.order.isTaxApply)
          .pipe(
            concatMap(savedSaleOrder =>  this.purchaseOrderService.convertToBill(savedSaleOrder.orderId)),
            map(data => POActions.postOrderSuccess({ data })),
            catchError(error => of(POActions.postOrderFailure({ error })))
          );
        }
      }
      )
    );
  });

  postPurchaseBill$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POActions.postBill),
      withLatestFrom(this.store.pipe(select(POSeletors.getOrderInfo))),
      map(([action, orderInfo]) => orderInfo ),
      switchMap( orderInfo =>
          this.purchaseOrderService.postBillOnServer(orderInfo.vendorId, orderInfo.currentOrderList, orderInfo.memo, orderInfo.isTaxApply)
            .pipe(
              map(data => POActions.postOrderSuccess({ data })),
              catchError(error => of(POActions.postOrderFailure({ error })))
            )
      )
    );
  });


  loadPurchaseOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POActions.loadPurchaseOrder),
      switchMap( action =>
          this.purchaseOrderService.getPurchaseOrder(action.data)
            .pipe(
              map(data => POActions.loadPurchaseOrderSuccess({ data })),
              catchError(error => of(POActions.loadPurchaseOrderFailure({ error })))
            )
      )
    );
  });


  loadBill$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POActions.loadBill),
      switchMap( action =>
          this.purchaseOrderService.getBill(action.data)
            .pipe(
              map(data => POActions.loadPurchaseOrderSuccess({ data })),
              catchError(error => of(POActions.loadPurchaseOrderFailure({ error })))
            )
      )
    );
  });

}




