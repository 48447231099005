
import { IVendor } from '@otrack-lib/models/vendor/vendor.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './vendor.actions';
import { ErrorModel } from '@otrack-lib/models/error.model';

export const currentVendorKey = 'vendor';

export interface VendorState {
   currentVendor: IVendor;
   error: ErrorModel;
   success: boolean;
   loading: boolean;
}

export const initialState: VendorState = {
  currentVendor: null,
  error: null,
  loading: false,
  success: false,
};

const Reducer = createReducer(
  initialState,

  on(Actions.loadCurrentVendor, state => ({...state, loading: true, success: false})),
  on(Actions.loadCurrentVendorSuccess, (state, {data}) => ({...state, currentVendor: data, success: true, loading: false })),
  on(Actions.loadCurrentVendorFailure, (state, { error }) => ({...state, error, loading: false, success: false})),
  on(Actions.clearCurrentVendor, ()  => ({...initialState})),
);

export function reducer(state: VendorState | undefined, action: Action) {
  return Reducer(state, action);
}
