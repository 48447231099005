import { createAction, props } from '@ngrx/store';
import { IVendor } from '@otrack-lib/models/vendor/vendor.model';
// current not loading whole vendor list in state


export const loadCurrentVendor = createAction(
  '[Vendor]  Set Current Vendor',
  props<{ data: number}>()
);

export const loadCurrentVendorSuccess = createAction(
  '[Vendor]  Load Current Vendor Success',
  props<{ data: IVendor}>()
);

export const loadCurrentVendorFailure = createAction(
  '[Vendor]  Load Current Vendor Failure',
  props<{ error: any}>()
);

export const clearCurrentVendor = createAction(
  '[Vendor]  Clear Current Vendor'
);
